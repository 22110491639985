import React, { useEffect, useRef } from 'react';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import Work from './components/Work';
import WorkDetail from './components/WorkDetail';
import Admin from './components/Admin';
import ScrollToTop from './components/ScrollTop';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';

const trackUserActivity = (page) => {
  // Skip tracking for the admin page
  if (page === '/admin') return;

  // Prepare the activity data
  const activity = {
    pageVisited: page,
    userAgent: navigator.userAgent,
  };

  // Send to the backend for logging
  axios.post('http://localhost:5000/track', activity).catch((error) => {
    console.error('Error tracking user activity:', error);
  });
};

const TrackPageView = () => {
  const location = useLocation();
  const isFirstLoad = useRef(true); // Flag to track initial load

  useEffect(() => {
    // Log activity only after the first load
    if (!isFirstLoad.current) {
      trackUserActivity(location.pathname);
    }
    isFirstLoad.current = false; // Set to false after first render
  }, [location]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TrackPageView />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work />} />
        <Route path="/work/:projectName" element={<WorkDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
