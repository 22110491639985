import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import './About.css'
import { logo } from './Images'
import Navbar from './Navbar'
import DocumentTitle from './DocumentTitle'


const About = () => {

  DocumentTitle('About Us');

  return (
    <div>
      <Navbar/>
      <div
        id="main"
      >
        <div
          className="framer-B3ZxN framer-H9isL framer-bHbcC framer-AJeL2 framer-sGVVB framer-sTOlC framer-1cvrekm"
          style={{
            minHeight: '100vh',
            width: 'auto'
          }}
        >
          <section
            className="framer-18dfd0t"
            data-framer-name="Hero"
            name="Hero"
          >
            <div
              className="framer-z3j7ut"
              data-framer-name="Container"
              name="Container"
            >
              <div
                className="framer-1mey2vj"
                data-border="true"
                data-framer-name="Content"
                name="Content"
              >
                <div
                  className="framer-1sisz1i"
                  data-framer-appear-id="1sisz1i"
                  data-framer-component-type="RichTextContainer"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: '0',
                    justifyContent: 'flex-start',
                    opacity: '1',
                    outline: 'none',
                    transform: 'none',
                    willChange: 'auto'
                  }}
                >
                  <h1
                    className="framer-text framer-styles-preset-1lbfmcj"
                    data-styles-preset="kKhzKEcvF"
                    style={{
                      '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                    }}
                  >
                    Innovative Design Solutions
                  </h1>
                </div>
              </div>
              <div
                className="framer-1r5sle7"
                data-framer-name="Buttom"
                name="Buttom"
              >
                <div
                  className="framer-1vqli5s"
                  data-framer-name="Button"
                  name="Button"
                >
                  <div className="ssr-variant hidden-1mdhrs7">
                    <div
                      className="framer-w6vfdo-container"
                      data-framer-appear-id="w6vfdo"
                      style={{
                        opacity: '1',
                        transform: 'none',
                        willChange: 'auto'
                      }}
                    >
                      <Link
                        className="framer-KDYjx framer-bHbcC framer-h6bfb5 framer-v-h6bfb5 framer-5uk4vs"
                        data-border="true"
                        data-framer-name="Primary"
                        to='/work'
                        style={{
                          '--border-bottom-width': '2px',
                          '--border-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                          '--border-left-width': '2px',
                          '--border-right-width': '2px',
                          '--border-style': 'solid',
                          '--border-top-width': '2px',
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: '100px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-suukqt"
                          data-framer-name="Wrapper"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-1xmx5dx"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <p
                              className="framer-text framer-styles-preset-zxebov"
                              data-styles-preset="TQv7oX2nc"
                            >
                              See works
                            </p>
                          </div>
                          <div
                            className="framer-j08lao-container"
                            style={{
                              opacity: '1'
                            }}
                          >
                            <div
                              style={{
                                display: 'contents'
                              }}
                            >
                              <svg
                                color="var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))"
                                focusable="false"
                                style={{
                                  color: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                                  display: 'inline-block',
                                  fill: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                                  flexShrink: '0',
                                  height: '100%',
                                  userSelect: 'none',
                                  width: '100%'
                                }}
                                viewBox="0 0 256 256"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  color="var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))"
                                  weight="regular"
                                >
                                  <path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          className="framer-wien62"
                          data-framer-name="BG"
                          style={{
                            backgroundColor: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            opacity: '0'
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="framer-97vaci"
                  data-framer-name="Content"
                  name="Content"
                >
                  <div
                    className="framer-fyv1ce"
                    data-framer-appear-id="fyv1ce"
                    data-framer-component-type="RichTextContainer"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: '0',
                      justifyContent: 'flex-start',
                      opacity: '1',
                      outline: 'none',
                      transform: 'none',
                      willChange: 'auto'
                    }}
                  >
                    <p
                      className="framer-text framer-styles-preset-zxebov"
                      data-styles-preset="TQv7oX2nc"
                      style={{
                        '--framer-text-alignment': 'left',
                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                      }}
                    >
                      Our inspired solutions have helped shape modern living feeling. Alluring spaces, internationally recognised for their product design and exceptional solutions for everyday homes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="framer-zjndhv"
            data-framer-name="Parallax image"
            name="Parallax image"
          >
            <div
              className="framer-115vzey"
              data-framer-name="Container"
              name="Container"
            >
              <div className="ssr-variant hidden-1mdhrs7">
                <div
                  className="framer-jzxdfr"
                  data-framer-appear-id="jzxdfr"
                  data-framer-name="Image"
                  name="Image"
                  style={{
                    opacity: '1',
                    transform: 'none',
                    willChange: 'auto'
                  }}
                >
                  <div
                    data-framer-background-image-wrapper="true"
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      decoding="async"
                      sizes="max(100vw, 1px)"
                      src="./Strix — Creative Agency _ Business Website_files/LrwtNhAMX2BqU3bU6xFcbNgISNg.jpg"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div
                className="framer-82esq0"
                data-framer-name="Stats"
                name="Stats"
              >
                <div
                  className="framer-1byok22"
                  data-border="true"
                  data-framer-name="Stats wraper"
                  name="Stats wraper"
                >
                  <div
                    className="framer-19ecatv"
                    data-framer-name="Stats"
                    name="Stats"
                  >
                    <div
                      className="framer-s0cfgo"
                      data-framer-name="Stats item"
                      name="Stats item"
                    >
                      <div
                        className="framer-7s1skr"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h2
                          className="framer-text framer-styles-preset-18i0ski"
                          data-styles-preset="iNmn7GJ8H"
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              7
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              0
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              +
                            </span>
                          </span>
                        </h2>
                      </div>
                      <div
                        className="framer-1v1hmpm"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          data-styles-preset="TQv7oX2nc"
                          style={{
                            '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              H
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              p
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              p
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              y
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              c
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              l
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className="framer-tst0q3"
                      data-framer-name="Stats item"
                      name="Stats item"
                    >
                      <div
                        className="framer-5b45j"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h2
                          className="framer-text framer-styles-preset-18i0ski"
                          data-styles-preset="iNmn7GJ8H"
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              1
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              5
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              0
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              +
                            </span>
                          </span>
                        </h2>
                      </div>
                      <div
                        className="framer-1e4z26y"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          data-styles-preset="TQv7oX2nc"
                          style={{
                            '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              C
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              m
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              p
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              l
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              d
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              P
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              j
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              c
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className="framer-e4fpcp"
                      data-framer-name="Stats item"
                      name="Stats item"
                    >
                      <div
                        className="framer-8qgjfr"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-u8d2be"
                          data-styles-preset="lLKQqybDq"
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              I
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              p
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              w
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              h
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'unset'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              l
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              c
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              u
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              m
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              F
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              E
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              d
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              A
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              b
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              b
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              O
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              l
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              E
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              c
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              u
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              U
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              h
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              S
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              p
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              h
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              f
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              D
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              d
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              P
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              m
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              m
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              i
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              n
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                opacity: '1',
                                transform: 'none',
                                willChange: 'auto'
                              }}
                            >
                              g
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
          <section
            className="framer-1fyk9x7"
            data-framer-name="Our story"
            name="Our story"
          >
            <div
              className="framer-1fpq17b"
              data-framer-name="Container"
              name="Container"
            >
              <div className="ssr-variant hidden-1mdhrs7 hidden-145auvr">
                <div className="framer-1lg3lqf-container">
                  <div
                    className="framer-f2zyl framer-bHbcC framer-1yvnjfv framer-v-bckhlr"
                    data-framer-name="Variant 2"
                    data-highlight="true"
                    style={{
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-ximhp5"
                      data-framer-name="Left line"
                      style={{
                        backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1irhho3"
                        data-framer-name="Left light"
                        style={{
                          background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.24) 0%, rgb(255, 255, 255) 51.3513%, rgba(255, 255, 255, 0.76) 100%)',
                          transform: 'translate3d(-493.745px, 0px, 0px) scale(0.237937, 1)',
                          transformOrigin: '50% 50% 0px',
                          willChange: 'auto'
                        }}
                      />
                    </div>
                    <div
                      className="framer-acn7ch"
                      data-border="true"
                      data-framer-name="Heading"
                      style={{
                        '--border-bottom-width': '2px',
                        '--border-color': 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                        '--border-left-width': '2px',
                        '--border-right-width': '2px',
                        '--border-style': 'solid',
                        '--border-top-width': '2px',
                        backgroundColor: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                        borderRadius: '100px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1aa2ufb"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          '--framer-link-text-color': 'rgb(0, 153, 255)',
                          '--framer-link-text-decoration': 'underline',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          data-styles-preset="TQv7oX2nc"
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              H
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              o
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              w
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              w
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              t
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              d
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className="framer-h7fp"
                      data-framer-name="Right line"
                      style={{
                        backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-4kbeux"
                        data-framer-name="Right light"
                        style={{
                          background: 'linear-gradient(227.057deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 51.3513%, rgba(255, 255, 255, 0) 100%)',
                          transform: 'translate3d(514.654px, 0px, 0px) scale(0.237937, 1)',
                          transformOrigin: '50% 50% 0px',
                          willChange: 'auto'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="framer-oo5mg4"
                data-framer-name="Content wrapper"
                name="Content wrapper"
              >
                <div
                  className="framer-1lkijjv"
                  data-framer-name="Content"
                  name="Content"
                >
                  <div
                    className="framer-13grto3"
                    data-framer-component-type="RichTextContainer"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: '0',
                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <h2
                      className="framer-text framer-styles-preset-18i0ski"
                      data-styles-preset="iNmn7GJ8H"
                    >
                      Our narrative originated from our pioneers
                    </h2>
                  </div>
                  <div
                    className="framer-dbdo39"
                    data-framer-name="Paragraph"
                    name="Paragraph"
                  >
                    <div
                      className="framer-v2bxc9"
                      data-framer-component-type="RichTextContainer"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text framer-styles-preset-zxebov"
                        data-styles-preset="TQv7oX2nc"
                        style={{
                          '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          marginTop: '40px'
                        }}
                      >
                        Founded in 2024, our agency began with a shared passion for technology and the internet's possibilities. Initially, Jagjot (Founder) worked alone as a front-end and blockchain developer. Since then, we’ve added designers and developers who share the same vision. We’ve grown into a skilled team focused on improving the web, step by step. Our success comes from our dedication, creativity, and teamwork.
                      </p>
                      <p
                        className="framer-text framer-styles-preset-zxebov"
                        data-styles-preset="TQv7oX2nc"
                        style={{
                          '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                        }}
                      >
                        We believe in a collaborative approach, working closely with clients to understand their vision and transform it into a reality. From concept to launch, we are committed to delivering exceptional results that drive growth and success. Whether you're a startup looking to make an impact or an established brand aiming to revamp your online presence, our team is here to bring your ideas to life with precision and passion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="framer-1obehhr"
            data-framer-name="Our values"
            name="Our values"
          >
            <div
              className="framer-1mdmf8q"
              data-framer-name="Container"
              name="Container"
            >
              <div className="ssr-variant hidden-1mdhrs7 hidden-145auvr">
                <div className="framer-l6pr6h-container">
                  <div
                    className="framer-f2zyl framer-bHbcC framer-1yvnjfv framer-v-bckhlr"
                    data-framer-name="Variant 2"
                    data-highlight="true"
                    style={{
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-ximhp5"
                      data-framer-name="Left line"
                      style={{
                        backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1irhho3"
                        data-framer-name="Left light"
                        style={{
                          background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.235) 0%, rgb(255, 255, 255) 51.3513%, rgba(255, 255, 255, 0.765) 100%)',
                          transform: 'translate3d(-497.333px, 0px, 0px) scale(0.233509, 1)',
                          transformOrigin: '50% 50% 0px',
                          willChange: 'auto'
                        }}
                      />
                    </div>
                    <div
                      className="framer-acn7ch"
                      data-border="true"
                      data-framer-name="Heading"
                      style={{
                        '--border-bottom-width': '2px',
                        '--border-color': 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                        '--border-left-width': '2px',
                        '--border-right-width': '2px',
                        '--border-style': 'solid',
                        '--border-top-width': '2px',
                        backgroundColor: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                        borderRadius: '100px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1aa2ufb"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          '--framer-link-text-color': 'rgb(0, 153, 255)',
                          '--framer-link-text-decoration': 'underline',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          data-styles-preset="TQv7oX2nc"
                        >
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              O
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              u
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              r
                            </span>
                          </span>
                          {' '}
                          <span
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              v
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              a
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              l
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              u
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              e
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                willChange: 'auto'
                              }}
                            >
                              s
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className="framer-h7fp"
                      data-framer-name="Right line"
                      style={{
                        backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-4kbeux"
                        data-framer-name="Right light"
                        style={{
                          background: 'linear-gradient(227.855deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 51.3513%, rgba(255, 255, 255, 0) 100%)',
                          transform: 'translate3d(531.25px, 0px, 0px) scale(0.233509, 1)',
                          transformOrigin: '50% 50% 0px',
                          willChange: 'auto'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="framer-125d7sy"
                data-framer-name="Values wrapper"
                name="Values wrapper"
              >
                <div
                  className="framer-qrj0kn"
                  data-framer-name="Left content"
                  name="Left content"
                >
                  <div
                    className="framer-v3i3gq"
                    data-framer-component-type="RichTextContainer"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: '0',
                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <h2
                      className="framer-text framer-styles-preset-18i0ski"
                      data-styles-preset="iNmn7GJ8H"
                      style={{
                        '--framer-text-alignment': 'left'
                      }}
                    >
                      The principles that steer all our actions

                    </h2>
                  </div>
                  <div
                    className="framer-1mbo5fo"
                    data-framer-component-type="RichTextContainer"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: '0',
                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <p
                      className="framer-text framer-styles-preset-zxebov"
                      data-styles-preset="TQv7oX2nc"
                      style={{
                        '--framer-text-alignment': 'left',
                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                      }}
                    >
                      The existing egestas is not easily overshadowed by a famed arch, it's fringed and held by Orci. Erat seems to be lecturing, while Mauris communicates, both are present beneath the Aenean and the non-aliquam.
                    </p>
                  </div>
                </div>
                <div
                  className="framer-20m9wr"
                  data-framer-name="Right content"
                  name="Right content"
                >
                  <div className="ssr-variant hidden-1mdhrs7 hidden-145auvr">
                    <div
                      className="framer-ykv3u9-container"
                      style={{
                        opacity: '1',
                        transform: 'none'
                      }}
                    >
                      <div
                        className="framer-yfDIm framer-AJeL2 framer-bHbcC framer-sm5xl6 framer-v-sm5xl6"
                        data-framer-name="Variant 1"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1',
                          width: '100%'
                        }}
                      >
                        <div
                          className="framer-642nmy"
                          data-framer-component-type="RichTextContainer"
                          style={{
                            '--extracted-1of0zx5': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            '--framer-link-text-color': 'rgb(0, 153, 255)',
                            '--framer-link-text-decoration': 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: '0',
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <h2
                            className="framer-text framer-styles-preset-18i0ski"
                            data-styles-preset="iNmn7GJ8H"
                            style={{
                              '--framer-text-alignment': 'center',
                              '--framer-text-color': 'var(--extracted-1of0zx5, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            01
                          </h2>
                        </div>
                        <div
                          className="framer-i8djk3"
                          data-framer-name="Noise"
                          style={{
                            opacity: '0.05'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              backgroundImage: 'url(https://framerusercontent.com/images/6mcf62RlDfRfU61Yg5vb2pefpi4.png)',
                              backgroundPosition: 'center top',
                              backgroundRepeat: 'repeat',
                              backgroundSize: '166.40px auto',
                              border: '0',
                              borderRadius: 'inherit',
                              bottom: '0',
                              left: '0',
                              position: 'absolute',
                              right: '0',
                              top: '0'
                            }}
                          />
                        </div>
                        <div
                          className="framer-4440i5"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-1jhbt0e"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h2
                              className="framer-text framer-styles-preset-18i0ski"
                              data-styles-preset="iNmn7GJ8H"
                              style={{
                                '--framer-text-alignment': 'center'
                              }}
                            >
                              Client Focus
                            </h2>
                          </div>
                          <div
                            className="framer-11dlvcy"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <p
                              className="framer-text framer-styles-preset-zxebov"
                              data-styles-preset="TQv7oX2nc"
                              style={{
                                '--framer-text-alignment': 'center',
                                '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                              }}
                            >
                              We focus on our clients' needs, providing customized solutions that go beyond expectations
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-1mdhrs7 hidden-145auvr">
                    <div
                      className="framer-1ft9rk9-container"
                      style={{
                        opacity: '1',
                        transform: 'none'
                      }}
                    >
                      <div
                        className="framer-yfDIm framer-AJeL2 framer-bHbcC framer-sm5xl6 framer-v-sm5xl6"
                        data-framer-name="Variant 1"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1',
                          width: '100%'
                        }}
                      >
                        <div
                          className="framer-642nmy"
                          data-framer-component-type="RichTextContainer"
                          style={{
                            '--extracted-1of0zx5': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            '--framer-link-text-color': 'rgb(0, 153, 255)',
                            '--framer-link-text-decoration': 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: '0',
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <h2
                            className="framer-text framer-styles-preset-18i0ski"
                            data-styles-preset="iNmn7GJ8H"
                            style={{
                              '--framer-text-alignment': 'center',
                              '--framer-text-color': 'var(--extracted-1of0zx5, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            02
                          </h2>
                        </div>
                        <div
                          className="framer-i8djk3"
                          data-framer-name="Noise"
                          style={{
                            opacity: '0.05'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              backgroundImage: 'url(https://framerusercontent.com/images/6mcf62RlDfRfU61Yg5vb2pefpi4.png)',
                              backgroundPosition: 'center top',
                              backgroundRepeat: 'repeat',
                              backgroundSize: '166.40px auto',
                              border: '0',
                              borderRadius: 'inherit',
                              bottom: '0',
                              left: '0',
                              position: 'absolute',
                              right: '0',
                              top: '0'
                            }}
                          />
                        </div>
                        <div
                          className="framer-4440i5"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-1jhbt0e"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h2
                              className="framer-text framer-styles-preset-18i0ski"
                              data-styles-preset="iNmn7GJ8H"
                              style={{
                                '--framer-text-alignment': 'center'
                              }}
                            >
                              Innovative Solutions
                            </h2>
                          </div>
                          <div
                            className="framer-11dlvcy"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <p
                              className="framer-text framer-styles-preset-zxebov"
                              data-styles-preset="TQv7oX2nc"
                              style={{
                                '--framer-text-alignment': 'center',
                                '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                              }}
                            >
                              We embrace new ideas and technologies to craft unique, forward-thinking designs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-1mdhrs7 hidden-145auvr">
                    <div
                      className="framer-1itba3k-container"
                      style={{
                        opacity: '1',
                        transform: 'none'
                      }}
                    >
                      <div
                        className="framer-yfDIm framer-AJeL2 framer-bHbcC framer-sm5xl6 framer-v-sm5xl6"
                        data-framer-name="Variant 1"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1',
                          width: '100%'
                        }}
                      >
                        <div
                          className="framer-642nmy"
                          data-framer-component-type="RichTextContainer"
                          style={{
                            '--extracted-1of0zx5': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            '--framer-link-text-color': 'rgb(0, 153, 255)',
                            '--framer-link-text-decoration': 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: '0',
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <h2
                            className="framer-text framer-styles-preset-18i0ski"
                            data-styles-preset="iNmn7GJ8H"
                            style={{
                              '--framer-text-alignment': 'center',
                              '--framer-text-color': 'var(--extracted-1of0zx5, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            03
                          </h2>
                        </div>
                        <div
                          className="framer-i8djk3"
                          data-framer-name="Noise"
                          style={{
                            opacity: '0.05'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              backgroundImage: 'url(https://framerusercontent.com/images/6mcf62RlDfRfU61Yg5vb2pefpi4.png)',
                              backgroundPosition: 'center top',
                              backgroundRepeat: 'repeat',
                              backgroundSize: '166.40px auto',
                              border: '0',
                              borderRadius: 'inherit',
                              bottom: '0',
                              left: '0',
                              position: 'absolute',
                              right: '0',
                              top: '0'
                            }}
                          />
                        </div>
                        <div
                          className="framer-4440i5"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-1jhbt0e"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h2
                              className="framer-text framer-styles-preset-18i0ski"
                              data-styles-preset="iNmn7GJ8H"
                              style={{
                                '--framer-text-alignment': 'center'
                              }}
                            >
                              Collaborative Spirit
                            </h2>
                          </div>
                          <div
                            className="framer-11dlvcy"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <p
                              className="framer-text framer-styles-preset-zxebov"
                              data-styles-preset="TQv7oX2nc"
                              style={{
                                '--framer-text-alignment': 'center',
                                '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                              }}
                            >
                              We believe in the power of collaboration, working closely with clients and each other to achieve the best results.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-1mdhrs7 hidden-145auvr">
                    <div
                      className="framer-nce7z5-container"
                      style={{
                        opacity: '1',
                        transform: 'none'
                      }}
                    >
                      <div
                        className="framer-yfDIm framer-AJeL2 framer-bHbcC framer-sm5xl6 framer-v-sm5xl6"
                        data-framer-name="Variant 1"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1',
                          width: '100%'
                        }}
                      >
                        <div
                          className="framer-642nmy"
                          data-framer-component-type="RichTextContainer"
                          style={{
                            '--extracted-1of0zx5': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            '--framer-link-text-color': 'rgb(0, 153, 255)',
                            '--framer-link-text-decoration': 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: '0',
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <h2
                            className="framer-text framer-styles-preset-18i0ski"
                            data-styles-preset="iNmn7GJ8H"
                            style={{
                              '--framer-text-alignment': 'center',
                              '--framer-text-color': 'var(--extracted-1of0zx5, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            04
                          </h2>
                        </div>
                        <div
                          className="framer-i8djk3"
                          data-framer-name="Noise"
                          style={{
                            opacity: '0.05'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              backgroundImage: 'url(https://framerusercontent.com/images/6mcf62RlDfRfU61Yg5vb2pefpi4.png)',
                              backgroundPosition: 'center top',
                              backgroundRepeat: 'repeat',
                              backgroundSize: '166.40px auto',
                              border: '0',
                              borderRadius: 'inherit',
                              bottom: '0',
                              left: '0',
                              position: 'absolute',
                              right: '0',
                              top: '0'
                            }}
                          />
                        </div>
                        <div
                          className="framer-4440i5"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-1jhbt0e"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h2
                              className="framer-text framer-styles-preset-18i0ski"
                              data-styles-preset="iNmn7GJ8H"
                              style={{
                                '--framer-text-alignment': 'center'
                              }}
                            >
                              Transparent Integrity

                            </h2>
                          </div>
                          <div
                            className="framer-11dlvcy"
                            data-framer-component-type="RichTextContainer"
                            style={{
                              '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              '--framer-link-text-color': 'rgb(0, 153, 255)',
                              '--framer-link-text-decoration': 'underline',
                              display: 'flex',
                              flexDirection: 'column',
                              flexShrink: '0',
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <p
                              className="framer-text framer-styles-preset-zxebov"
                              data-styles-preset="TQv7oX2nc"
                              style={{
                                '--framer-text-alignment': 'center',
                                '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                              }}
                            >
                              We build trust through honesty, clear communication, and ethical practices in everything we do.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default About