import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="project-box-container">
        <div className="project-box">
          <h4 className="project-title">HAVE A PROJECT IN MIND?</h4>
          <h1 className="gradient-text">LET'S WORK</h1>
          <Link to="/contact" className="border-text">Let's work</Link>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-container">
          <h2>Flenex.io</h2>

          <div className="footer-contact">
            <h4>Contact</h4>
            <p><a href="mailto:info@flenex.io">info@flenex.io</a></p>
            <p><a href="tel:+917455998013">+91 74559-98013</a></p>
          </div>

          <div className="footer-center">
            <h4>Pages</h4>
            <ul>
              <li><Link to="/" className='scroll-show'>Home</Link></li>
              <li><Link to="/about">About us</Link></li>
              <li><Link to="/work">Work</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>

          <div className="footer-right">
            <h4>Social</h4>
            <ul>
              <li><a href="https://github.com/flenex-io/">GitHub</a></li>
              <li><a href="https://instagram.com/flenex.io/">Instagram</a></li>
              <li><a href="#">Twitter</a></li>
              <li><a href="https://linkedin.com/company/flenex-io">LinkedIn</a></li>
            </ul>
          </div>

          <hr className="footer-divider" />

          <div className="footer-bottom">
            <p>All rights reserved 2024 © Flenex.io</p>
            <div className="footer-links">
              <a href="#">Terms & Conditions</a>
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
