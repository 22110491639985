import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/"><h2>Flenex.io</h2></Link>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
      </div>

      <div className={`navbar-links-cylinder ${menuOpen ? 'open' : ''}`}>
        {window.innerWidth <= 768 ? (
          <>
            <div className="nav-item home-item">
              <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
              </svg>
              <Link to="/">Home</Link>
            </div>
            <div className="nav-item home-item">
              <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
              </svg>
              <Link to="/about">About</Link>
            </div>
            <div className="nav-item home-item">
              <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
              </svg>
              <Link to="/work">Work</Link>
            </div>
            <div className="nav-item home-item">
              <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
              </svg>
              <Link to="/contact">Contact</Link>
              <div className="navbar-book-call">
                <a href="https://calendly.com/info-jagjot" target="_blank" className="book-call-button">Book a Call</a>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="navbar-navbar">
              <div className="nav-item home-item top-home">
                <Link to="/">Home</Link>
              </div>
              <div className="nav-item home-item bottom-home">
                <Link to="/">Home</Link>
              </div>
            </div>

            <div className="navbar-navbar">
              <div className="nav-item home-item top-home">
                <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                  <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
                </svg>
                <Link to="/about">About</Link>
              </div>
              <div className="nav-item home-item bottom-home">
                <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                  <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
                </svg>
                <Link to="/about">About</Link>
              </div>
            </div>

            <div className="navbar-navbar">
              <div className="nav-item home-item top-home">
                <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                  <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
                </svg>
                <Link to="/work">Work</Link>
              </div>
              <div className="nav-item home-item bottom-home">
                <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                  <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
                </svg>
                <Link to="/work">Work</Link>
              </div>
            </div>

            <div className="navbar-navbar">
              <div className="nav-item home-item top-home">
                <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                  <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
                </svg>
                <Link to="/contact">Contact</Link>
              </div>
              <div className="nav-item home-item bottom-home">
                <svg className="separator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                  <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z"></path>
                </svg>
                <Link to="/contact">Contact</Link>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="navbar-book-call">
        <a href="https://calendly.com/info-jagjot" target="_blank" className="book-call-button">Book a Call</a>
      </div>
    </nav>
  );
};

export default Navbar;
