import React from 'react'
import { useParams } from 'react-router-dom'
import DocumentTitle from './DocumentTitle';
import projectData from './ProjectData';
import Footer from './Footer';
import Navbar from './Navbar';
import './WorkDetail.css'

const WorkDetail = () => {

    const { projectName } = useParams();
    const project = projectData.find(
        (proj) => proj.name === decodeURIComponent(projectName)
    );
    DocumentTitle(project ? project.name : 'Project Details');

    if (!project) {
        return <h2>Project not found</h2>;
    }
    return (
        <div>

            <div
                id="main"
            >
                <div
                    className="framer-XCcpy framer-AJeL2 framer-sGVVB framer-3dAED framer-bHbcC framer-w74e5"
                    style={{
                        minHeight: '100vh',
                        width: 'auto'
                    }}
                >
                    <section className="framer-1plssq3">
                        <div className="framer-1u0dvb7">
                            <Navbar />
                        </div>
                    </section>
                    <section
                        className="framer-nvi6b9"
                        data-framer-name="Work content"
                        name="Work content"
                    >
                        <div
                            className="framer-1et0e97"
                            data-framer-name="Container"
                            name="Container"
                        >
                            <div className="ssr-variant hidden-15qdmc3">
                                <div
                                    className="framer-dce3n9"
                                    data-framer-appear-id="dce3n9"
                                    data-framer-name="Image"
                                    name="Image"
                                    style={{
                                        opacity: '1',
                                        transform: 'none',
                                        willChange: 'auto'
                                    }}
                                >
                                    <div
                                        data-framer-background-image-wrapper="true"
                                        style={{
                                            borderRadius: 'inherit',
                                            bottom: '0',
                                            left: '0',
                                            position: 'absolute',
                                            right: '0',
                                            top: '0'
                                        }}
                                    >
                                        <img
                                            alt=""
                                            decoding="async"
                                            sizes="min(max(100vw - 64px, 1px), 1440px)"
                                            src={project.image}
                                            style={{
                                                borderRadius: 'inherit',
                                                display: 'block',
                                                height: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center',
                                                width: '100%'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="framer-nlzxvd">
                                <div
                                    className="framer-bveb69"
                                    data-framer-name="Content"
                                    name="Content"
                                >
                                    <div
                                        className="framer-130klu2"
                                        data-framer-name="Challange"
                                        name="Challange"
                                        style={{
                                            opacity: '1',
                                            transform: 'none'
                                        }}
                                    >
                                        <div
                                            className="framer-iyto6a"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <h2
                                                className="framer-text framer-styles-preset-18i0ski"
                                                data-styles-preset="iNmn7GJ8H"
                                                style={{
                                                    '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))'
                                                }}
                                            >
                                                The challange
                                            </h2>
                                        </div>
                                        <div
                                            className="framer-qlah97"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <p
                                                className="framer-text framer-styles-preset-u8d2be"
                                                data-styles-preset="lLKQqybDq"
                                            >
                                                <span
                                                    className="framer-text"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    {project.challange}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ssr-variant hidden-uytcbh hidden-15qdmc3">
                                        <div
                                            className="framer-nagevp"
                                            data-framer-name="Image 1"
                                            name="Image 1"
                                            style={{
                                                opacity: '1',
                                                transform: 'none'
                                            }}
                                        >
                                            <div
                                                data-framer-background-image-wrapper="true"
                                                style={{
                                                    borderRadius: 'inherit',
                                                    bottom: '0',
                                                    left: '0',
                                                    position: 'absolute',
                                                    right: '0',
                                                    top: '0'
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    sizes="calc(max((min(max(100vw - 64px, 1px), 1440px) - 64px) / 3, 50px) * 2 + 32px)"
                                                    src={project.image1}
                                                    style={{
                                                        borderRadius: 'inherit',
                                                        display: 'block',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                        width: '100%'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="framer-jtyz0w"
                                        data-framer-name="Solution"
                                        name="Solution"
                                        style={{
                                            opacity: '1',
                                            transform: 'none'
                                        }}
                                    >
                                        <div
                                            className="framer-152n7ag"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <h2
                                                className="framer-text framer-styles-preset-18i0ski"
                                                data-styles-preset="iNmn7GJ8H"
                                                style={{
                                                    '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))'
                                                }}
                                            >
                                                The solution
                                            </h2>
                                        </div>
                                        <div
                                            className="framer-vo4a4g"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <p
                                                className="framer-text framer-styles-preset-u8d2be"
                                                data-styles-preset="lLKQqybDq"
                                            >
                                                <span
                                                    className="framer-text"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    {project.solution}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ssr-variant hidden-uytcbh hidden-15qdmc3">
                                        <div
                                            className="framer-bi370z"
                                            data-framer-name="Image 2"
                                            name="Image 2"
                                            style={{
                                                opacity: '1',
                                                transform: 'none'
                                            }}
                                        >
                                            <div
                                                data-framer-background-image-wrapper="true"
                                                style={{
                                                    borderRadius: 'inherit',
                                                    bottom: '0',
                                                    left: '0',
                                                    position: 'absolute',
                                                    right: '0',
                                                    top: '0'
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    sizes="calc(max((min(max(100vw - 64px, 1px), 1440px) - 64px) / 3, 50px) * 2 + 32px)"
                                                    src={project.image2}
                                                    style={{
                                                        borderRadius: 'inherit',
                                                        display: 'block',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                        width: '100%'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="framer-esocb6"
                                        data-framer-name="Result"
                                        name="Result"
                                        style={{
                                            opacity: '1',
                                            transform: 'none'
                                        }}
                                    >
                                        <div
                                            className="framer-16ielb"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <h2
                                                className="framer-text framer-styles-preset-18i0ski"
                                                data-styles-preset="iNmn7GJ8H"
                                                style={{
                                                    '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))'
                                                }}
                                            >
                                                The result
                                            </h2>
                                        </div>
                                        <div
                                            className="framer-ngz3tt"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <p
                                                className="framer-text framer-styles-preset-u8d2be"
                                                data-styles-preset="lLKQqybDq"
                                            >
                                                <span
                                                    className="framer-text"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    {project.result}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ssr-variant hidden-uytcbh hidden-15qdmc3">
                                        <div
                                            className="framer-10qjrmc"
                                            data-framer-name="Image 3"
                                            name="Image 3"
                                            style={{
                                                opacity: '1',
                                                transform: 'none'
                                            }}
                                        >
                                            <div
                                                data-framer-background-image-wrapper="true"
                                                style={{
                                                    borderRadius: 'inherit',
                                                    bottom: '0',
                                                    left: '0',
                                                    position: 'absolute',
                                                    right: '0',
                                                    top: '0'
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    sizes="calc(max((min(max(100vw - 64px, 1px), 1440px) - 64px) / 3, 50px) * 2 + 32px)"
                                                    src={project.image3}
                                                    style={{
                                                        borderRadius: 'inherit',
                                                        display: 'block',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                        width: '100%'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="framer-k2030j"
                                    data-border="true"
                                    data-framer-name="Project details"
                                    name="Project details"
                                    style={{
                                        opacity: '1',
                                        transform: 'none'
                                    }}
                                >
                                    <div
                                        className="framer-iqbu2w"
                                        data-framer-name="Heading"
                                        name="Heading"
                                    >
                                        <div
                                            className="framer-1isim3s"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <h4
                                                className="framer-text framer-styles-preset-2ffhdr"
                                                data-styles-preset="nnXmDzCUm"
                                                style={{
                                                    '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))'
                                                }}
                                            >
                                                {project.name}
                                            </h4>
                                        </div>
                                        <div
                                            className="framer-w5dgg5"
                                            data-framer-component-type="RichTextContainer"
                                            data-framer-name="Title"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none'
                                            }}
                                        >
                                            <p
                                                className="framer-text framer-styles-preset-u8d2be"
                                                data-styles-preset="lLKQqybDq"
                                            >
                                                {<span
                                                    className="framer-text"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    {project.description}
                                                </span>}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="framer-1x48h59"
                                        data-framer-name="Details"
                                        name="Details"
                                    >
                                        <div
                                            className="framer-gzw9ts"
                                            data-border="true"
                                            data-framer-name="Details item"
                                            name="Details item"
                                        >
                                            <div
                                                className="framer-1cr8lse"
                                                data-framer-component-type="RichTextContainer"
                                                data-framer-name="Title"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexShrink: '0',
                                                    justifyContent: 'flex-start',
                                                    outline: 'none',
                                                    transform: 'none'
                                                }}
                                            >
                                                <p
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    data-styles-preset="TQv7oX2nc"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    Project type
                                                </p>
                                            </div>
                                            <div
                                                className="framer-1nzkr11"
                                                data-framer-component-type="RichTextContainer"
                                                data-framer-name="Title"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexShrink: '0',
                                                    justifyContent: 'flex-start',
                                                    outline: 'none',
                                                    transform: 'none'
                                                }}
                                            >
                                                <p
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    data-styles-preset="TQv7oX2nc"
                                                    style={{
                                                        '--framer-text-alignment': 'right',
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    {project.category}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="framer-m19zv8"
                                            data-border="true"
                                            data-framer-name="Details item"
                                            name="Details item"
                                        >
                                            <div
                                                className="framer-2fo6ui"
                                                data-framer-component-type="RichTextContainer"
                                                data-framer-name="Title"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexShrink: '0',
                                                    justifyContent: 'flex-start',
                                                    outline: 'none',
                                                    transform: 'none'
                                                }}
                                            >
                                                <p
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    data-styles-preset="TQv7oX2nc"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    Completed
                                                </p>
                                            </div>
                                            <div
                                                className="framer-1a87au3"
                                                data-framer-component-type="RichTextContainer"
                                                data-framer-name="Title"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexShrink: '0',
                                                    justifyContent: 'flex-start',
                                                    outline: 'none',
                                                    transform: 'none'
                                                }}
                                            >
                                                <p
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    data-styles-preset="TQv7oX2nc"
                                                    style={{
                                                        '--framer-text-alignment': 'right',
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    {project.complete}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="framer-1tk5pho"
                                            data-border="true"
                                            data-framer-name="Details item"
                                            name="Details item"
                                        >
                                            <div
                                                className="framer-1jhpc7h"
                                                data-framer-component-type="RichTextContainer"
                                                data-framer-name="Title"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexShrink: '0',
                                                    justifyContent: 'flex-start',
                                                    outline: 'none',
                                                    transform: 'none'
                                                }}
                                            >
                                                <p
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    data-styles-preset="TQv7oX2nc"
                                                    style={{
                                                        '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                                                    }}
                                                >
                                                    View project
                                                </p>
                                            </div>
                                            <div className="ssr-variant hidden-uytcbh hidden-15qdmc3">
                                                <div className="framer-1q7w9x4-container">
                                                    <a
                                                        className="framer-KDYjx framer-bHbcC framer-h6bfb5 framer-v-h6bfb5 framer-5uk4vs"
                                                        data-border="true"
                                                        data-framer-name="Primary"
                                                        href=""
                                                        rel="noopener"
                                                        style={{
                                                            '--border-bottom-width': '2px',
                                                            '--border-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                                                            '--border-left-width': '2px',
                                                            '--border-right-width': '2px',
                                                            '--border-style': 'solid',
                                                            '--border-top-width': '2px',
                                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                                            borderRadius: '100px',
                                                            opacity: '1'
                                                        }}
                                                        target="_blank"
                                                    >
                                                        <div
                                                            className="framer-suukqt"
                                                            data-framer-name="Wrapper"
                                                            style={{
                                                                opacity: '1'
                                                            }}
                                                        >
                                                            <div
                                                                className="framer-1xmx5dx"
                                                                data-framer-component-type="RichTextContainer"
                                                                style={{
                                                                    '--framer-link-text-color': 'rgb(0, 153, 255)',
                                                                    '--framer-link-text-decoration': 'underline',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    flexShrink: '0',
                                                                    justifyContent: 'flex-start',
                                                                    opacity: '1',
                                                                    outline: 'none',
                                                                    transform: 'none'
                                                                }}
                                                            >
                                                                <p
                                                                    className="framer-text framer-styles-preset-zxebov"
                                                                    data-styles-preset="TQv7oX2nc"
                                                                >
                                                                    {project.Link}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="framer-j08lao-container"
                                                                style={{
                                                                    opacity: '1'
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'contents'
                                                                    }}
                                                                >
                                                                    <svg
                                                                        color="var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))"
                                                                        focusable="false"
                                                                        style={{
                                                                            color: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                                                                            display: 'inline-block',
                                                                            fill: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                                                                            flexShrink: '0',
                                                                            height: '100%',
                                                                            userSelect: 'none',
                                                                            width: '100%'
                                                                        }}
                                                                        viewBox="0 0 256 256"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <g
                                                                            color="var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))"
                                                                            weight="regular"
                                                                        >
                                                                            <path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z" />
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="framer-wien62"
                                                            data-framer-name="BG"
                                                            style={{
                                                                backgroundColor: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                                                                opacity: '0'
                                                            }}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="ssr-variant hidden-uytcbh hidden-15qdmc3">
                        <div
                            className="framer-18g7v6z-container"
                            style={{
                                opacity: '1',
                                transform: 'none'
                            }}
                        >
                            <Footer />
                        </div>
                    </div>
                </div>
                <div
                    className="framer-XCcpy framer-AJeL2 framer-sGVVB framer-3dAED framer-bHbcC"
                    id="overlay"
                />
            </div>
        </div>
    )
}

export default WorkDetail