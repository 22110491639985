import React from 'react'
import './Contact.css'
import Footer from './Footer'
import Navbar from './Navbar'
import DocumentTitle from './DocumentTitle'

const Contact = () => {

    DocumentTitle( "Contact Us" );

    return (
        <div>
            <div id="main"
            >
                <div
                    className="framer-p5U6d framer-sTOlC framer-sGVVB framer-bHbcC framer-WZdhZ framer-1b868xi"
                    style={{
                        minHeight: '100vh',
                        width: 'auto'
                    }}
                >
                    <Navbar/>
                    <section
                        className="framer-1u3xh80"
                        name="Hero"
                    >
                        <div
                            className="framer-da0oyc"
                            name="Container"
                        >
                            <div className="framer-tuw4bs" style={{ display: 'flex' }}>
                                {/* Left Side - Form */}
                                <div className="framer-1p1f7tg" style={{ flex: 1, padding: '2rem' }}>
                                    <div className="framer-1yfv6rl">
                                        <div
                                            className="framer-1nxmiz1"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none',
                                            }}
                                        >
                                            <h3 className="framer-text framer-styles-preset-okgdl1">
                                                Contact us
                                            </h3>
                                        </div>
                                        <div
                                            className="framer-1fjwoin"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexShrink: '0',
                                                justifyContent: 'flex-start',
                                                outline: 'none',
                                                transform: 'none',
                                            }}
                                        >
                                            <p
                                                className="framer-text framer-styles-preset-u8d2be"
                                                style={{
                                                    '--framer-text-color':
                                                        'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                                                }}
                                            >
                                                Get in touch with us today—whether you have a question, need a custom solution, or just want to chat, we're here to help!
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="framer-1iar18l"
                                        style={{
                                            opacity: '1',
                                            transform: 'none',
                                            willChange: 'auto',
                                        }}
                                    >
                                        <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <div style={{ marginBottom: '1rem' }}>
                                                <label
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    style={{
                                                        '--framer-text-color':
                                                            'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                                                    }}
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Your Name"
                                                    style={{
                                                        fontSize: '16px',
                                                        padding: '24px',
                                                        borderRadius: '12px',
                                                        border: 'none',
                                                        width: '100%',
                                                        backgroundColor: 'rgb(13, 13, 13)',
                                                        outline: 'none',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </div>
                                            <div style={{ marginBottom: '1rem' }}>
                                                <label
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    style={{
                                                        '--framer-text-color':
                                                            'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                                                    }}
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    placeholder="Your Email"
                                                    style={{
                                                        fontSize: '16px',
                                                        padding: '24px',
                                                        borderRadius: '12px',
                                                        border: 'none',
                                                        width: '100%',
                                                        backgroundColor: 'rgb(13, 13, 13)',
                                                        outline: 'none',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </div>
                                            <div style={{ marginBottom: '1rem' }}>
                                                <label
                                                    className="framer-text framer-styles-preset-zxebov"
                                                    style={{
                                                        '--framer-text-color':
                                                            'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                                                    }}
                                                >
                                                    Message
                                                </label>
                                                <textarea
                                                    placeholder="Your Message"
                                                    rows="10"
                                                    style={{
                                                        fontSize: '16px',
                                                        padding: '24px',
                                                        borderRadius: '12px',
                                                        border: 'none',
                                                        width: '100%',
                                                        resize: 'vertical',
                                                        backgroundColor: 'rgb(13, 13, 13)',
                                                        outline: 'none',
                                                        color: '#fff',
                                                    }}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                style={{
                                                    backgroundColor: '#fff',
                                                    borderRadius: '12px',
                                                    padding: '24px',
                                                    color: '#000',
                                                    border: 'none',
                                                    fontSize: '13px',
                                                    fontWeight: '600',
                                                    cursor: 'pointer',
                                                    width: '100%',
                                                }}
                                            >
                                                Send Message
                                            </button>
                                        </form>
                                    </div>
                                </div>

                                {/* Right Side - Image */}
                                <div className="ssr-variant hidden-1ygmmjj hidden-1b868xi" style={{ flex: 1, position: 'relative' }}>
                                    <div className="framer-1t2ppvf"
                                        style={{
                                            opacity: '1',
                                            transform: 'none',
                                            willChange: 'auto'
                                        }}
                                    >
                                        <div style={{
                                            borderRadius: 'inherit',
                                            bottom: '0',
                                            left: '0',
                                            position: 'absolute',
                                            right: '0',
                                            top: '0'
                                        }}>
                                            <img
                                                alt=""
                                                decoding="async"
                                                src="https://framerusercontent.com/images/k6uK4sKYpdqmRQbzLUQrBeMHiOk.jpg"
                                                style={{
                                                    borderRadius: 'inherit',
                                                    display: 'block',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                    width: '100%'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="ssr-variant hidden-1ygmmjj">
                    </div>
                    <div className="ssr-variant hidden-1ygmmjj hidden-1b868xi">
                        <div
                            className="framer-j55urv-container"
                            style={{
                                opacity: '1',
                                transform: 'none'
                            }}
                        >
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact