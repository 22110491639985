import { Atomix, Atomix1, Atomix2, Atomix3, Speakchain, Speakchain1, Speakchain2, Flxhouse, Flxhouse1 } from "./Images";

const projectData = [
  {
    name: 'Atomix',
    description: 'The Atomix Project is a passion project that combines design and development to create smooth, easy-to-use digital experiences.',
    image: Atomix,
    image1: Atomix1,
    image2: Atomix2,
    image3: Atomix3,
    category: 'Design and Development',
    complete: "17 Aug 2024",
    Link: 'https://atomixx.netlify.app/',
    challange: "During the development of the Atomix Project, several challenges arose. Balancing design aesthetics with functionality was a primary issue, as it required creating a visually appealing interface that was also practical and easy to use.",
    solution: "To tackle the challenges, the focus was on creating simple designs aligned with the project’s goals. Regular research kept things up-to-date, while breaking tasks into smaller parts made progress easier. Open communication between design and development helped balance creativity and functionality.",
    result: 'As a result, the Atomix Project successfully delivered user-friendly digital experiences. It combined modern design with efficient development, overcoming the challenges of limited time and resources while maintaining a high standard of quality.'
  },
  {
    name: 'Speakchain',
    description: 'SpeakChain is a Web3 project that allows users to connect their wallets, join public channels, and chat securely, prioritizing privacy and data ownership.',
    image: Speakchain,
    image1: Speakchain1,
    image2: Speakchain2,
    image3: Speakchain1,
    complete: "26 Sep 2024",
    category: 'Web3',
    Link: 'https://www.flenex.io',
    overview: "Speakchain is a Web3 platform designed for open discussions among users on any topic without restrictions. Despite facing numerous challenges during development, the project ultimately succeeded and delivered outstanding results.",
    overview1: "In this project, you first connect your Web3 wallet. Once connected, a new page will appear where you join any channel and can chat with other users about any topic without restriction.",
    challange: 'SpeakChain had a lot of problems during development. The chat feature didn’t work, and many users couldn’t join public channels, which affted the motivation to complete this project. There were security issues in the smart contracts that could risk user data. High gas fees made transactions too expensive, discouraging people from using the platform. Because of these problems, the project was left untouched for a couple of weeks.',
    solution: 'To fix these problems, the chat feature was carefully checked and repaired, allowing users to join public channels. Security issues in the smart contracts were resolved through testing and audits. Changes were made to lower gas fees, making transactions cheaper. A simpler user interface was designed to improve usability, and safer connections were set up for smoother communication. It took almost 2 months to solve all the issues and make the platform live.',
    result: "After resolving the issues, SpeakChain successfully launched as a user-friendly platform where people can connect their wallets, join public channels, and chat securely. Users now enjoy a reliable experience with improved performance, lower costs, and better data protection. The project has gained positive feedback, and its engaging features have helped build a growing community, encouraging more users to participate and interact."
  },
  {
    name: 'FLX Houses',
    description: 'FLX Houses is a modern real estate marketing platform for flexible housing solutions.',
    image: Flxhouse,
    image1: Flxhouse1,
    category: 'Design and Development',
    complete: "15 Sep 2024",
    Link: 'https://atomixx.netlify.app/',
    overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo dia",
  }
];

export default projectData;