import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useDocumentTitle = (projectName) => {
  const location = useLocation();

  useEffect(() => {
    document.title = `Flenex.io | ${projectName}`;
  }, [location, projectName]);
};

export default useDocumentTitle;
