import React, { useState, useEffect } from 'react';
import './Admin.css'; // Import the CSS file for styling
import axios from 'axios';

const Admin = () => {
  // State variables
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [analyticsData, setAnalyticsData] = useState([]);

  // Valid credentials for admin access
  const validUsername = '1'; // Update as needed
  const validPassword = '1'; // Update as needed

  // Handle login process
  const handleLogin = () => {
    if (username === validUsername && password === validPassword) {
      setIsLoggedIn(true);
      setError('');
      fetchAnalyticsData(); // Fetch analytics data upon successful login
    } else {
      setError('Invalid username or password');
    }
  };

  // Fetch analytics data from local storage when the user logs in
  useEffect(() => {
    if (isLoggedIn) {
      const storedData = JSON.parse(localStorage.getItem('analyticsData')) || [];
      setAnalyticsData(storedData); // Set the analytics data state
    }
  }, [isLoggedIn]);

  // Fetch analytics data from the backend
  const fetchAnalyticsData = async () => {
    try {
      const response = await axios.get('http://localhost:5000/analytics'); // Update if needed
      setAnalyticsData(response.data); // Set the fetched data to state
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  // Clear analytics data from local storage
  const clearAnalyticsData = () => {
    localStorage.removeItem('analyticsData'); // Clear local storage
    setAnalyticsData([]); // Clear state
  };

  // Login form component
  const LoginForm = () => (
    <div className="login-form">
      <h2>Admin Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="input-field"
      />
      <br />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input-field"
      />
      <br />
      <button onClick={handleLogin} className="login-button">Login</button>
      {error && <p className="error-message">{error}</p>} {/* Display error message */}
    </div>
  );

  // Protected admin page view
  const ProtectedPage = () => (
    <div className="analytics-view">
      <h2>Welcome to the Admin Page!</h2>
      <h3>Website Analytics</h3>
      {analyticsData.length > 0 ? (
        <ul className="analytics-list">
          {analyticsData.map((entry, index) => (
            <li key={index} className="analytics-item">
              <span className="page">{entry.pageVisited}</span> - 
              <span className="visit-count">Visits: {entry.visitCount}</span> - 
              <span className="timestamp">{new Date(entry.timestamp).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No analytics data available.</p>
      )}
      <button onClick={clearAnalyticsData} className="clear-logs-button">Clear Logs</button>
    </div>
  );

  return (
    <div className="admin-container">
      {isLoggedIn ? <ProtectedPage /> : <LoginForm />} {/* Conditional rendering */}
    </div>
  );
};

export default Admin;

