import React from 'react';
import './Demo.css';

const Demo = () => {
  const cards = [
    {
      heading: "Branding & Identity",
      details: ["App design", "Website Design", "Landing Page Design"]
    },
    {
      heading: "Web & App Development",
      details: ["Webflow Website", "Shopify Website", "Custom Website", "Webflow Website"]
    },
    {
      heading: "Smart Contract Development",
      details: ["dApp Development", "Web 3 Wallet Development", "Smart Contract Development", "Web 3 Exchange Development"]
    },
    {
      heading: "SEO / Marketing",
      details: ["SEO Strategies", "Keyword Analysis", "Google Ads / Meta Ads", "Analytics and Reporting"]
    },
    {
      heading: "Digital Product Maintenance",
      details: ["dApp Maintenance", "Website Maintenance", "Mobile App Maintenance"]
    }
  ];

  return (
    <section className="card-section">
      <div className="section-header">
        <svg
          color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
          focusable="false"
          style={{
            color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
            display: 'inline-block',
            fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
            height: '32px',
            width: '32px'
          }}
          viewBox="0 0 256 256"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g weight="regular">
            <path d="M229.66,157.66l-48,48a8,8,0,0,1-11.32-11.32L204.69,160H128A104.11,104.11,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.69l-34.35-34.34a8,8,0,0,1,11.32-11.32l48,48A8,8,0,0,1,229.66,157.66Z" />
          </g>
        </svg>
        <h2 className="section-title">What we do for you</h2>
      </div>
      <div className="card-list">
        {cards.map((card, index) => (
          <div key={index} className="card">
            <div className="card-heading">
              <h3>{card.heading}</h3>
            </div>
            <div className="card-details">
              {card.details.map((detail, idx) => (
                <p key={idx}>{detail}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Demo;
