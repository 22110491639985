import React from 'react';
import './App.css';
import { Link } from 'react-router-dom'
import { Atomix, Flxhouse, Speakchain } from './Images';
import Footer from './Footer';
import Navbar from './Navbar'
import DocumentTitle from './DocumentTitle'

const Work = () => {

  DocumentTitle("Projects ")
  return (
    <div
      id="main"
    >
      <div
        className="framer-BLhCi framer-H9isL framer-1bxpraq"
        style={{
          minHeight: '100vh',
          width: 'auto'
        }}
      >
        <Navbar />
        <section
          className="framer-467ql8"
          name="Hero"
        >
          <div
            className="framer-1hvf52t"
          >
            <div
              className="framer-j4oy5x"
            >
              <div
                className="framer-pepu05"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexShrink: '0',
                  justifyContent: 'flex-start',
                  opacity: '1',
                  outline: 'none',
                  transform: 'none',
                  willChange: 'auto'
                }}
              >
                <h1
                  className="framer-text framer-styles-preset-1lbfmcj"
                  data-styles-preset="kKhzKEcvF"
                  style={{
                    '--framer-text-color': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))'
                  }}
                >
                  our projects
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section
          className="framer-1aq5up3"
        >
          <div
            className="framer-11eguhm"
          >
            <div
              className="framer-13vl69f"
            >
              <div className="ssr-variant hidden-p6rfin hidden-z7ac01">
                <div
                  className="framer-1vw2f15-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <Link
                    className="framer-CB4f8 framer-sTOlC framer-bHbcC framer-kqh4m3 framer-v-kqh4m3 framer-k9pnig"
                    data-border="true"
                    to={`/work/${encodeURIComponent('Atomix')}`}
                    style={{
                      '--border-bottom-width': '2px',
                      '--border-color': 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                      '--border-left-width': '2px',
                      '--border-right-width': '2px',
                      '--border-style': 'solid',
                      '--border-top-width': '2px',
                      borderRadius: '24px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-8myfw3"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '24px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-lfrggm"
                        style={{
                          opacity: '1'
                        }}
                      >
                        <div
                          style={{
                            borderRadius: 'inherit',
                            bottom: '0',
                            left: '0',
                            position: 'absolute',
                            right: '0',
                            top: '0'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            src={Atomix}
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-19qvulk"
                      style={{
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-14uzetd"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h3
                          className="framer-text framer-styles-preset-okgdl1"
                        >
                          Atomix
                        </h3>
                      </div>
                      <div
                        className="framer-95r3dl"
                        style={{
                          '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}
                        >
                          Web3
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="ssr-variant hidden-p6rfin hidden-z7ac01">
                <div
                  className="framer-1vw2f15-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <Link
                    className="framer-CB4f8 framer-sTOlC framer-bHbcC framer-kqh4m3 framer-v-kqh4m3 framer-k9pnig"
                    data-border="true"
                    to={`/work/${encodeURIComponent('Speakchain')}`}
                    style={{
                      '--border-bottom-width': '2px',
                      '--border-color': 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                      '--border-left-width': '2px',
                      '--border-right-width': '2px',
                      '--border-style': 'solid',
                      '--border-top-width': '2px',
                      borderRadius: '24px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-8myfw3"
                      style={{
                        borderRadius: '24px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-lfrggm"
                        data-framer-name="Image"
                        style={{
                          opacity: '1'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            bottom: '0',
                            left: '0',
                            position: 'absolute',
                            right: '0',
                            top: '0'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            sizes="max((min(max(100vw - 64px, 1px), 1440px) - 24px) / 2, 50px)"
                            src={Speakchain}
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-19qvulk"
                      data-framer-name="Post"
                      style={{
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-14uzetd"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h3
                          className="framer-text framer-styles-preset-okgdl1"
                          data-styles-preset="JlgdESwF7"
                        >
                          Speakchain
                        </h3>
                      </div>
                      <div
                        className="framer-95r3dl"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          data-styles-preset="TQv7oX2nc"
                          style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}
                        >
                          Design & Dev
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="ssr-variant hidden-p6rfin hidden-z7ac01">
                <div
                  className="framer-1vw2f15-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <Link
                    className="framer-CB4f8 framer-sTOlC framer-bHbcC framer-kqh4m3 framer-v-kqh4m3 framer-k9pnig"
                    data-border="true"
                    data-framer-name="Desktop"
                    to={`/work/${encodeURIComponent('FLX Houses')}`}
                    style={{
                      '--border-bottom-width': '2px',
                      '--border-color': 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                      '--border-left-width': '2px',
                      '--border-right-width': '2px',
                      '--border-style': 'solid',
                      '--border-top-width': '2px',
                      borderRadius: '24px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-8myfw3"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '24px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-lfrggm"
                        data-framer-name="Image"
                        style={{
                          opacity: '1'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            bottom: '0',
                            left: '0',
                            position: 'absolute',
                            right: '0',
                            top: '0'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            sizes="max((min(max(100vw - 64px, 1px), 1440px) - 24px) / 2, 50px)"
                            src={Flxhouse}
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-19qvulk"
                      data-framer-name="Post"
                      style={{
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-14uzetd"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h3
                          className="framer-text framer-styles-preset-okgdl1"
                          data-styles-preset="JlgdESwF7"
                        >
                          Flx Houses
                        </h3>
                      </div>
                      <div
                        className="framer-95r3dl"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          data-styles-preset="TQv7oX2nc"
                          style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}
                        >
                          Design & Dev
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="ssr-variant hidden-p6rfin hidden-z7ac01">
                <div className="framer-k148p5-container" />
              </div>
            </div>
          </div>
        </section>
        <div className="ssr-variant hidden-p6rfin hidden-z7ac01">
          <div
            className="framer-m0ewij-container"
            style={{
              opacity: '1',
              transform: 'none'
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work