import React, { useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import DocumentTitle from './DocumentTitle'
import { Atomix, Flxhouse, Speakchain } from './Images'
import Demo from './Demo'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion";

const Home = () => {

  const headingVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  const wordVariants = {
    hidden: { opacity: 0, y: 20 }, // Start hidden and slightly below
    visible: { opacity: 1, y: 0 }, // Fade in and move to original position
  };

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.09, // Adjusts the delay between each child
      },
    },
  };

  const paragraph = "We shape tomorrow's brands with boldness, vision, and collaborative spirit. Welcome to our agency, where innovation thrives.";

  const dotRef = useRef(null);
  const speed = .1;
  let direction = -1;
  let position = 500;

  const moveDot = () => {
    const containerHeight = 15;
    position += speed * direction;

    if (position <= 0) {
      direction = 1;
      position = 0;
    } else if (position >= containerHeight) {
      direction = -1;
      position = containerHeight;
    }

    if (dotRef.current) {
      dotRef.current.style.transform = `translate(-50%, ${-position}px)`;
    }

    requestAnimationFrame(moveDot);
  };

  useEffect(() => {
    requestAnimationFrame(moveDot);
  }, []);


  DocumentTitle('We Build Digital Products');

  return (
    <div>
      <Navbar />
      <div
        id="main"
      >
        <div className="framer-GvxKv framer-bHbcC framer-AJeL2 framer-sGVVB framer-72rtr7"
          style={{ minHeight: '100vh', width: 'auto' }}>
          {/* <div className="framer-uqjqq1-container"
            style={{ opacity: '1', transform: 'translateX(-50%)', willChange: 'auto' }} >
            <nav className="framer-nxgMv framer-1cse5k4 framer-v-1cse5k4"
              
              style={{
                border: '2px solid rgba(255, 255, 255, 0.1)',
                borderRadius: '100px'
              }}>
              <div className="framer-jyeyw"
                style={{
                  backdropFilter: 'blur(8px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderRadius: '100px'
                }}>
                <div className="framer-r44a5s">
                  <div className="framer-wchjjf-container">
                    <Link className="framer-9Bwqw framer-bHbcC framer-w0bbmi framer-v-w0bbmi framer-1i3u5k" to='/'>
                      <div className="framer-1xkujpr">
                        <div className="framer-1u69kap"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}>
                          <p className="framer-text framer-styles-preset-zxebov"
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                            }}>
                            Home
                          </p>
                        </div>
                        <div className="framer-11sgrrk"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}>
                          <p className="framer-text framer-styles-preset-zxebov"
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}>
                            Home
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="framer-1s9eoav-container">
                    <Link className="framer-9Bwqw framer-bHbcC framer-w0bbmi framer-v-w0bbmi framer-1i3u5k"
                      to='/about'>
                      <div className="framer-1qutw4u-container" >
                        <div>
                          <svg
                            color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                            focusable="false"
                            style={{
                              color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              display: 'inline-block',
                              fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            
                              height: '100%',
                              userSelect: 'none',
                              width: '100%'
                            }}
                            viewBox="0 0 256 256"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                              weight="bold"
                            >
                              <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="framer-1xkujpr">
                        <div className="framer-1u69kap"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p
                            className="framer-text framer-styles-preset-zxebov"
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                            }}
                          >
                            About
                          </p>
                        </div>
                        <div className="framer-11sgrrk"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p className="framer-text framer-styles-preset-zxebov"
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}>
                            About
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="framer-1swqa70-container">
                    <Link className="framer-9Bwqw framer-bHbcC framer-w0bbmi framer-v-w0bbmi framer-1i3u5k" to="/work">
                      <div className="framer-1qutw4u-container">
                        <div>
                          <svg color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                            focusable="false"
                            style={{
                              color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              display: 'inline-block',
                              fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            
                              height: '100%',
                              userSelect: 'none',
                              width: '100%'
                            }}
                            viewBox="0 0 256 256"
                            xmlns="http://www.w3.org/2000/svg">
                            <g color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))" weight="bold">
                              <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="framer-1xkujpr">
                        <div className="framer-1u69kap"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}>
                          <p className="framer-text framer-styles-preset-zxebov" style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                          }}>
                            Work
                          </p>
                        </div>
                        <div className="framer-11sgrrk"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p className="framer-text framer-styles-preset-zxebov" style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}>
                            Work
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="framer-ohwofq-container">
                    <Link className="framer-9Bwqw framer-bHbcC framer-w0bbmi framer-v-w0bbmi framer-1i3u5k"
                      to="/contact">
                      <div className="framer-1qutw4u-container">
                        <div>
                          <svg color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                            focusable="false"
                            style={{
                              color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                              display: 'inline-block',
                              fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            
                              height: '100%',
                              userSelect: 'none',
                              width: '100%'
                            }}
                            viewBox="0 0 256 256"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                              weight="bold"
                            >
                              <path d="M144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="framer-1xkujpr">
                        <div className="framer-1u69kap"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p className="framer-text framer-styles-preset-zxebov"
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255)))'
                            }}
                          >
                            Contact
                          </p>
                        </div>
                        <div className="framer-11sgrrk"
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            color: 'rgb(0, 153, 255)',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p className="framer-text framer-styles-preset-zxebov"
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            Contact
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="framer-1kttw31"
                style={{ backgroundColor: 'rgb(255, 255, 255)', filter: 'blur(10px)', opacity: '0.25', transform: 'rotate(90.5837deg)' }}
              />
            </nav>
          </div> */}
          <section className="framer-1w64kqc">
            <div className="framer-1e4p18r-container">
              <div className="framer-PDSZu framer-12lvst8 framer-v-1tolmi7"
                style={{ height: '100%', opacity: '1', width: '100%' }}>
                <div className="framer-10mc3c8"
                  style={{ backgroundColor: 'var(--token-e4bdcb15-346d-47ef-8cbf-50e190901dd5, rgb(8, 8, 8))' }} />
              </div>
            </div>
            <div className="framer-jud34a">
              <div
                className="framer-6gyo3o"
              >
                <svg
                  className="framer-1cj37us"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',

                    justifyContent: 'flex-start',
                    opacity: '1',
                    outline: 'none',
                    transform: 'none'
                  }}
                  viewBox="0 0 1312 220"
                >
                  <foreignObject
                    className="framer-fit-text"
                    height="100%"
                    style={{
                      overflow: 'visible',
                      transformOrigin: 'center center'
                    }}
                    transform="scale(1)"
                    width="100%"
                  >
                    <h1
                      className="framer-text"
                      style={{
                        '--font-selector': 'RlM7TWFucm9wZS1leHRyYWJvbGQ=',
                        '--framer-font-family': '"Manrope", "Manrope Placeholder", sans-serif',
                        '--framer-font-size': '183.2358080860136px',
                        '--framer-font-weight': '800',
                        '--framer-text-alignment': 'center',
                        '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                        '--framer-text-transform': 'uppercase',
                        fontSize: '220px'
                      }}
                    >
                      <span
                        className="framer-text"
                        data-text-fill="true"
                        style={{
                          backgroundImage: 'linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 23%, rgba(255, 255, 255, 0.26) 74%, rgba(255, 255, 255, 0) 100%)'
                        }}
                      >
                        Flenex.io
                      </span>
                    </h1>
                  </foreignObject>
                </svg>
              </div>
              <div
                className="framer-z2kmxi"
                data-framer-appear-id="z2kmxi"
                data-framer-name="Buttom"
                name="Buttom"
                style={{
                  opacity: '1',
                  transform: 'none',
                  willChange: 'auto'
                }}
              >
                <div
                  className="framer-1ubak7u"
                  data-framer-name="Year"
                  name="Year"
                >
                  <div
                    className="framer-1kf3m9z"

                    style={{
                      display: 'flex',
                      flexDirection: 'column',

                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <p
                      className="framer-text framer-styles-preset-zxebov"

                      style={{
                        '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))'
                      }}
                    >
                      Since - Y:2024
                    </p>
                  </div>
                </div>
                <div className="framer-1l7sjd4-container" style={{ position: 'relative', height: '35px', width: '22px' }}>
                  <div
                    className="framer-H2tEu framer-efmjs3 framer-v-ib0doz"
                    style={{
                      border: '2px solid white',
                      borderRadius: '100px',
                      height: '100%',
                      opacity: '1',
                      width: '100%',
                      position: 'relative'
                    }}
                  >
                    <div
                      ref={dotRef}
                      className="framer-1ayy6ci"
                      style={{
                        backgroundColor: 'white',
                        width: '3px',
                        height: '5px',
                        position: 'absolute',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                      }}
                    />
                  </div>
                </div>
                <div
                  className="framer-bnsqie"
                  data-framer-name="Location"
                  name="Location"
                >
                  <div
                    className="framer-105andf"

                    style={{
                      display: 'flex',
                      flexDirection: 'column',

                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <p
                      className="framer-text framer-styles-preset-zxebov"

                      style={{
                        '--framer-text-alignment': 'right',
                        '--framer-text-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))'
                      }}
                    >
                      We Build Products That Stand Out
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="framer-1po6kex" id="about-us">
      <div className="framer-s97bu7" name="Container">
        <div className="framer-316jaf" name="Heading">
          <div className="framer-1jztn2m" name="Title">
            <div className="framer-5fby9j-container" style={{ opacity: '1', transform: 'none' }}>
              <div style={{ display: 'contents' }}>
                <svg
                  color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                  focusable="false"
                  style={{
                    color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                    display: 'inline-block',
                    fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                    height: '100%',
                    userSelect: 'none',
                    width: '100%'
                  }}
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                    weight="regular"
                  >
                    <path d="M229.66,157.66l-48,48a8,8,0,0,1-11.32-11.32L204.69,160H128A104.11,104.11,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.69l-34.35-34.34a8,8,0,0,1,11.32-11.32l48,48A8,8,0,0,1,229.66,157.66Z" />
                  </g>
                </svg>
              </div>
            </div>
            <motion.div
              className="framer-70not5"
              initial="hidden"
              animate="visible"
              variants={headingVariants}
              transition={{ duration: 0.5 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                outline: 'none',
                transform: 'none'
              }}
            >
              <h2 className="framer-text framer-styles-preset-18i0ski">Our Story</h2>
            </motion.div>
          </div>
          <div className="framer-1g4gymh">
            <motion.div
              className="framer-1ozog5n"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
              transition={{ duration: 0.5 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                outline: 'none',
                transform: 'none'
              }}
            >
              <motion.p
                className="framer-text framer-styles-preset-u8d2be"
                style={{ margin: 0 }} // Remove default margins
              >
                {paragraph.split(" ").map((word, index) => (
                  <motion.span
                    key={index}
                    variants={wordVariants}
                    transition={{ duration: 0.5 }}
                  >
                    {word}{" "}
                  </motion.span>
                ))}
              </motion.p>
            </motion.div>
          </div>
        </div>
        <div className="framer-23l8js">
          <div className="framer-1kejiwe-container">
            <div
              className="framer-6nqcS framer-1gq6h96 framer-v-2cm2zs"
              style={{
                borderRadius: '24px',
                opacity: '1',
                width: '100%'
              }}
            >
              <div className="framer-92t1uk">
                <div
                  style={{
                    borderRadius: 'inherit',
                    inset: '0px',
                    position: 'absolute'
                  }}
                >
                  <img
                    alt=""
                    decoding="async"
                    loading="lazy"
                    src="https://framerusercontent.com/images/saYDEr4aPCI4jM4UceQQHNYl5yU.jpg"
                    style={{
                      borderRadius: 'inherit',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center center',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div
                className="framer-z4cs86"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  opacity: '1'
                }}
              />
              <div
                className="framer-abbt12"
                style={{
                  opacity: '0.1'
                }}
              >
                <div
                  style={{
                    backgroundImage: 'url("https://framerusercontent.com/images/6mcf62RlDfRfU61Yg5vb2pefpi4.png")',
                    backgroundPosition: 'center top',
                    backgroundRepeat: 'repeat',
                    backgroundSize: '166.4px',
                    border: '0px',
                    borderRadius: 'inherit',
                    inset: '0px',
                    position: 'absolute'
                  }}
                />
              </div>
              <div
                className="framer-ormtu7"
                data-framer-name="Opener"
                style={{
                  backgroundColor: 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                  transform: 'none',
                  transformOrigin: '50% 50% 0px'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
          <section
            className="framer-kusbr1"
            data-framer-name="Work"
            name="Work"
          >
            <div
              className="framer-4iytvp"
              data-framer-name="Container"
              name="Container"
            >
              <div
                className="framer-1cfshpm"
                data-framer-name="Heading"
                name="Heading"
              >
                <div
                  className="framer-by56ky"
                  data-framer-name="Title"
                  name="Title"
                >
                  <div
                    className="framer-1vk8g1y-container"
                    style={{
                      opacity: '1',
                      transform: 'none'
                    }}
                  >
                    <div
                      style={{
                        display: 'contents'
                      }}
                    >
                      <svg
                        color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                        focusable="false"
                        style={{
                          color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                          display: 'inline-block',
                          fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',

                          height: '100%',
                          userSelect: 'none',
                          width: '100%'
                        }}
                        viewBox="0 0 256 256"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                          weight="regular"
                        >
                          <path d="M229.66,157.66l-48,48a8,8,0,0,1-11.32-11.32L204.69,160H128A104.11,104.11,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.69l-34.35-34.34a8,8,0,0,1,11.32-11.32l48,48A8,8,0,0,1,229.66,157.66Z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="framer-37qht5"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <h2 className="framer-text framer-styles-preset-18i0ski">
                      Featured works
                    </h2>
                  </div>
                </div>
                <div className="framer-w49rwk-container">
                  <a className="framer-KDYjx framer-bHbcC framer-h6bfb5 framer-v-13ixrju framer-5uk4vs"
                    href="https://strix.framer.website/work"
                    style={{
                      '--border-bottom-width': '0px',
                      '--border-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                      '--border-left-width': '0px',
                      '--border-right-width': '0px',
                      '--border-style': 'solid',
                      '--border-top-width': '0px',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '100px',
                      opacity: '1'
                    }}
                  >
                    <div className="framer-suukqt">
                      <div
                        className="framer-1xmx5dx"
                        style={{
                          color: 'rgb(0, 153, 255)',
                          textDecoration: 'none',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p className="framer-text framer-styles-preset-zxebov">
                          View all
                        </p>
                      </div>
                      <div className="framer-1b5sv9n"
                        style={{
                          color: 'rgb(0, 153, 255)',
                          textDecoration: 'underline',
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p className="framer-text framer-styles-preset-zxebov">
                          View all
                        </p>
                      </div>
                    </div>
                    <div className="framer-6k82ok-container">
                      <div
                        style={{
                          display: 'contents'
                        }}
                      >
                        <svg
                          color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                          focusable="false"
                          style={{
                            color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            display: 'inline-block',
                            fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',

                            height: '100%',
                            userSelect: 'none',
                            width: '100%'
                          }}
                          viewBox="0 0 256 256"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                            weight="fill"
                          >
                            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,109.66-32,32a8,8,0,0,1-11.32-11.32L148.69,136H88a8,8,0,0,1,0-16h60.69l-18.35-18.34a8,8,0,0,1,11.32-11.32l32,32A8,8,0,0,1,173.66,133.66Z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                className="framer-iv305g"
                data-framer-name="Work collection"
                name="Work collection"
              >
                <div
                  className="framer-147rshz-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <Link
                    className="framer-CB4f8 framer-sTOlC framer-bHbcC framer-kqh4m3 framer-v-kqh4m3 framer-k9pnig"
                    to={`/work/${encodeURIComponent('Atomix')}`}
                    style={{
                      '--border-bottom-width': '2px',
                      '--border-color': 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                      '--border-left-width': '2px',
                      '--border-right-width': '2px',
                      '--border-style': 'solid',
                      '--border-top-width': '2px',
                      borderRadius: '24px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-8myfw3"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '24px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-lfrggm"
                        data-framer-name="Image"
                        style={{
                          opacity: '1'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            inset: '0px',
                            position: 'absolute'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            loading="lazy"
                            sizes="max((min(max(100vw - 64px, 1px), 1440px) - 24px) / 2, 50px)"
                            src={Atomix}
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-19qvulk"
                      data-framer-name="Post"

                    >
                      <div
                        className="framer-14uzetd"

                        style={{
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h3
                          className="framer-text framer-styles-preset-okgdl1"

                        >
                          Atomix
                        </h3>
                      </div>
                      <div
                        className="framer-95r3dl"

                        style={{
                          '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"

                          style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}
                        >
                          Design & Development
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="framer-147rshz-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <Link
                    className="framer-CB4f8 framer-sTOlC framer-bHbcC framer-kqh4m3 framer-v-kqh4m3 framer-k9pnig"
                    to={`/work/${encodeURIComponent('Speakchain')}`}
                    style={{
                      '--border-bottom-width': '2px',
                      '--border-color': 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                      '--border-left-width': '2px',
                      '--border-right-width': '2px',
                      '--border-style': 'solid',
                      '--border-top-width': '2px',
                      borderRadius: '24px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-8myfw3"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '24px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-lfrggm"
                        data-framer-name="Image"
                        style={{
                          opacity: '1'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            inset: '0px',
                            position: 'absolute'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            loading="lazy"
                            sizes="max((min(max(100vw - 64px, 1px), 1440px) - 24px) / 2, 50px)"
                            src={Speakchain}
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-19qvulk"
                      data-framer-name="Post"

                    >
                      <div
                        className="framer-14uzetd"

                        style={{
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h3
                          className="framer-text framer-styles-preset-okgdl1"

                        >
                          Speakchain
                        </h3>
                      </div>
                      <div
                        className="framer-95r3dl"

                        style={{
                          '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"

                          style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}
                        >
                          Web3
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="framer-147rshz-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <Link
                    className="framer-CB4f8 framer-sTOlC framer-bHbcC framer-kqh4m3 framer-v-kqh4m3 framer-k9pnig"
                    to={`/work/${encodeURIComponent('FLX Houses')}`}
                    style={{
                      '--border-bottom-width': '2px',
                      '--border-color': 'var(--token-b99cef36-c24f-494d-8296-27b130de0cd7, rgb(0, 0, 0))',
                      '--border-left-width': '2px',
                      '--border-right-width': '2px',
                      '--border-style': 'solid',
                      '--border-top-width': '2px',
                      borderRadius: '24px',
                      opacity: '1',
                      width: '100%',
                      willChange: 'auto'
                    }}
                  >
                    <div
                      className="framer-8myfw3"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '24px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-lfrggm"
                        data-framer-name="Image"
                        style={{
                          transform: 'none',
                          transformOrigin: '50% 50% 0px'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            inset: '0px',
                            position: 'absolute'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            loading="lazy"
                            src={Flxhouse}
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-19qvulk"
                      data-framer-name="Post"

                    >
                      <div
                        className="framer-14uzetd"

                        style={{
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none',
                          transformOrigin: '50% 50% 0px'
                        }}
                      >
                        <h3
                          className="framer-text framer-styles-preset-okgdl1"

                        >
                          Flx Houses
                        </h3>
                      </div>
                      <div
                        className="framer-95r3dl"

                        style={{
                          '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                          display: 'flex',
                          flexDirection: 'column',

                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none',
                          transformOrigin: '50% 50% 0px'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"

                          style={{
                            '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                          }}
                        >
                          Design & Development
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <Demo />
          {/* <section
            className="framer-1vdlxfo"
            data-framer-name="Testimonials"
            id="testimonials"
            name="Testimonials"
          >
            <div
              className="framer-n3a53v"
              data-framer-name="Container"
              name="Container"
            >
              <div
                className="framer-l338s6"
                data-framer-name="Heading"
                name="Heading"
              >
                <div
                  className="framer-itfupl"
                  data-framer-name="Title"
                  name="Title"
                >
                  <div
                    className="framer-1teulj1-container"
                    style={{
                      opacity: '0',
                      transform: 'translateY(20px)'
                    }}
                  >
                    <div
                      style={{
                        display: 'contents'
                      }}
                    >
                      <svg
                        color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                        focusable="false"
                        style={{
                          color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                          display: 'inline-block',
                          fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                        
                          height: '100%',
                          userSelect: 'none',
                          width: '100%'
                        }}
                        viewBox="0 0 256 256"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                          weight="regular"
                        >
                          <path d="M229.66,157.66l-48,48a8,8,0,0,1-11.32-11.32L204.69,160H128A104.11,104.11,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.69l-34.35-34.34a8,8,0,0,1,11.32-11.32l48,48A8,8,0,0,1,229.66,157.66Z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    className="framer-picpr"
                    
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    
                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <h2
                      className="framer-text framer-styles-preset-18i0ski"
                      
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          opacity: '0.001',
                          transform: 'translateX(0px) translateY(10px) scale(1) rotate(0deg) skewX(0deg) skewY(0deg)'
                        }}
                      >
                        The
                      </span>
                      {' '}
                      <span
                        style={{
                          display: 'inline-block',
                          opacity: '0.001',
                          transform: 'translateX(0px) translateY(10px) scale(1) rotate(0deg) skewX(0deg) skewY(0deg)'
                        }}
                      >
                        Heart
                      </span>
                      {' '}
                      <span
                        style={{
                          display: 'inline-block',
                          opacity: '0.001',
                          transform: 'translateX(0px) translateY(10px) scale(1) rotate(0deg) skewX(0deg) skewY(0deg)'
                        }}
                      >
                        of
                      </span>
                      {' '}
                      <span
                        style={{
                          display: 'inline-block',
                          opacity: '0.001',
                          transform: 'translateX(0px) translateY(10px) scale(1) rotate(0deg) skewX(0deg) skewY(0deg)'
                        }}
                      >
                        Our
                      </span>
                      {' '}
                      <span
                        style={{
                          display: 'inline-block',
                          opacity: '0.001',
                          transform: 'translateX(0px) translateY(10px) scale(1) rotate(0deg) skewX(0deg) skewY(0deg)'
                        }}
                      >
                        Business
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className="framer-18r6jk7"
                
                name="Wrapper"
              >
                <div
                  className="framer-1ue0tw7"
                  data-framer-name="Testimonial collection"
                  name="Testimonial collection"
                >
                  <div
                    className="framer-syy4n7-container"
                    style={{
                      opacity: '1',
                      transform: 'scale(0.8)'
                    }}
                  >
                    <div
                      className="framer-iNI1P framer-3dAED framer-bHbcC framer-18t3i4e framer-v-18t3i4e"
                      
                      style={{
                        backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                        borderRadius: '24px',
                        opacity: '1',
                        transform: 'scale(0.8)',
                        width: '100%'
                      }}
                    >
                      <div
                        className="framer-r2mt9p"
                        
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-92ecdu"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-4kq1y9"
                            
                            style={{
                              
                              display: 'flex',
                              flexDirection: 'column',
                            
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h4
                              className="framer-text framer-styles-preset-2ffhdr"
                              
                              style={{
                                color: 'rgb(104, 104, 104)'
                              }}
                            >
                              "Strix transformed our content strategy and helped us establish a strong online presence. Their insights and creativity brought our brand to life, resulting in increased website traffic and better customer retention. They’re more than just a service provider; they’re a true partner in our growth."
                            </h4>
                          </div>
                          <div
                            className="framer-kv1t2e"
                            data-framer-name="Profile"
                            style={{
                              opacity: '1'
                            }}
                          >
                            <div
                              className="framer-1sc5wxo"
                              style={{
                                opacity: '1'
                              }}
                            >
                              <div
                                className="framer-dz3ie2"
                                
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                
                                  justifyContent: 'flex-start',
                                  opacity: '1',
                                  outline: 'none',
                                  transform: 'none'
                                }}
                              >
                                <h4
                                  className="framer-text framer-styles-preset-2ffhdr"
                                  
                                >
                                  Sarah Bona
                                </h4>
                              </div>
                              <div
                                className="framer-150483g"
                                
                                style={{
                                  '--extracted-1eung3n': 'rgb(153, 153, 153)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                
                                  justifyContent: 'flex-start',
                                  opacity: '1',
                                  outline: 'none',
                                  transform: 'none'
                                }}
                              >
                                <h4
                                  className="framer-text framer-styles-preset-2ffhdr"
                                  
                                  style={{
                                    '--framer-text-color': 'var(--extracted-1eung3n, rgb(153, 153, 153))'
                                  }}
                                >
                                  Creative Impact Studios
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="framer-1ro4j7r"
                          data-framer-name="Image"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              borderRadius: 'inherit',
                              inset: '0px',
                              position: 'absolute'
                            }}
                          >
                            <img
                              alt=""
                              decoding="async"
                              sizes="max((max(min(max(100vw - 64px, 1px), 1440px), 50px) - 182px) / 2, 1px)"
                              src="./Strix — Creative Agency _ Business Website_files/37urjh2XqHiyj4aeL09K85fQF8Y.jpg"
                              srcSet="https://framerusercontent.com/images/37urjh2XqHiyj4aeL09K85fQF8Y.jpg?scale-down-to=1024 683w,https://framerusercontent.com/images/37urjh2XqHiyj4aeL09K85fQF8Y.jpg?scale-down-to=2048 1366w,https://framerusercontent.com/images/37urjh2XqHiyj4aeL09K85fQF8Y.jpg?scale-down-to=4096 2732w,https://framerusercontent.com/images/37urjh2XqHiyj4aeL09K85fQF8Y.jpg 5464w"
                              style={{
                                borderRadius: 'inherit',
                                display: 'block',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center center',
                                width: '100%'
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="framer-syy4n7-container"
                    style={{
                      opacity: '1',
                      transform: 'scale(0.8)'
                    }}
                  >
                    <div
                      className="framer-iNI1P framer-3dAED framer-bHbcC framer-18t3i4e framer-v-18t3i4e"
                      
                      style={{
                        backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                        borderRadius: '24px',
                        opacity: '1',
                        transform: 'scale(0.8)',
                        width: '100%'
                      }}
                    >
                      <div
                        className="framer-r2mt9p"
                        
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-92ecdu"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-4kq1y9"
                            
                            style={{
                              
                              display: 'flex',
                              flexDirection: 'column',
                            
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h4
                              className="framer-text framer-styles-preset-2ffhdr"
                              
                              style={{
                                color: 'rgb(104, 104, 104)'
                              }}
                            >
                              "The team at Strix is incredibly talented and professional. They took our email marketing to the next level, creating personalized campaigns that boosted our open rates by 40%. Their attention to detail and dedication to our success made all the difference. I highly recommend them!"
                            </h4>
                          </div>
                          <div
                            className="framer-kv1t2e"
                            data-framer-name="Profile"
                            style={{
                              opacity: '1'
                            }}
                          >
                            <div
                              className="framer-1sc5wxo"
                              style={{
                                opacity: '1'
                              }}
                            >
                              <div
                                className="framer-dz3ie2"
                                
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                
                                  justifyContent: 'flex-start',
                                  opacity: '1',
                                  outline: 'none',
                                  transform: 'none'
                                }}
                              >
                                <h4
                                  className="framer-text framer-styles-preset-2ffhdr"
                                  
                                >
                                  Alice Smith
                                </h4>
                              </div>
                              <div
                                className="framer-150483g"
                                
                                style={{
                                  '--extracted-1eung3n': 'rgb(153, 153, 153)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                
                                  justifyContent: 'flex-start',
                                  opacity: '1',
                                  outline: 'none',
                                  transform: 'none'
                                }}
                              >
                                <h4
                                  className="framer-text framer-styles-preset-2ffhdr"
                                  
                                  style={{
                                    '--framer-text-color': 'var(--extracted-1eung3n, rgb(153, 153, 153))'
                                  }}
                                >
                                  Design Hub
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="framer-1ro4j7r"
                          data-framer-name="Image"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              borderRadius: 'inherit',
                              inset: '0px',
                              position: 'absolute'
                            }}
                          >
                            <img
                              alt=""
                              decoding="async"
                              sizes="max((max(min(max(100vw - 64px, 1px), 1440px), 50px) - 182px) / 2, 1px)"
                              src="./Strix — Creative Agency _ Business Website_files/5BSSCYSiS3p196aYHJcWiyE4RE.jpg"
                              srcSet="https://framerusercontent.com/images/5BSSCYSiS3p196aYHJcWiyE4RE.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/5BSSCYSiS3p196aYHJcWiyE4RE.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/5BSSCYSiS3p196aYHJcWiyE4RE.jpg?scale-down-to=2048 2048w,https://framerusercontent.com/images/5BSSCYSiS3p196aYHJcWiyE4RE.jpg?scale-down-to=4096 4096w,https://framerusercontent.com/images/5BSSCYSiS3p196aYHJcWiyE4RE.jpg 8192w"
                              style={{
                                borderRadius: 'inherit',
                                display: 'block',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center center',
                                width: '100%'
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="framer-syy4n7-container"
                    style={{
                      opacity: '1',
                      transform: 'scale(0.8)'
                    }}
                  >
                    <div
                      className="framer-iNI1P framer-3dAED framer-bHbcC framer-18t3i4e framer-v-18t3i4e"
                      
                      style={{
                        backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                        borderRadius: '24px',
                        opacity: '1',
                        transform: 'scale(0.8)',
                        width: '100%'
                      }}
                    >
                      <div
                        className="framer-r2mt9p"
                        
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          borderRadius: '24px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-92ecdu"
                          data-framer-name="Content"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            className="framer-4kq1y9"
                            
                            style={{
                              
                              display: 'flex',
                              flexDirection: 'column',
                            
                              justifyContent: 'flex-start',
                              opacity: '1',
                              outline: 'none',
                              transform: 'none'
                            }}
                          >
                            <h4
                              className="framer-text framer-styles-preset-2ffhdr"
                              
                              style={{
                                color: 'rgb(104, 104, 104)'
                              }}
                            >
                              "Working with Strix has been a game-changer for our business. Their expertise in social media campaigns helped us reach a wider audience and significantly increased our engagement rates. They truly understood our brand and delivered a strategy that exceeded our expectations. We couldn’t be happier with the results!"
                            </h4>
                          </div>
                          <div
                            className="framer-kv1t2e"
                            data-framer-name="Profile"
                            style={{
                              opacity: '1'
                            }}
                          >
                            <div
                              className="framer-1sc5wxo"
                              style={{
                                opacity: '1'
                              }}
                            >
                              <div
                                className="framer-dz3ie2"
                                
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                
                                  justifyContent: 'flex-start',
                                  opacity: '1',
                                  outline: 'none',
                                  transform: 'none'
                                }}
                              >
                                <h4
                                  className="framer-text framer-styles-preset-2ffhdr"
                                  
                                >
                                  Kevin Davis
                                </h4>
                              </div>
                              <div
                                className="framer-150483g"
                                
                                style={{
                                  '--extracted-1eung3n': 'rgb(153, 153, 153)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                
                                  justifyContent: 'flex-start',
                                  opacity: '1',
                                  outline: 'none',
                                  transform: 'none'
                                }}
                              >
                                <h4
                                  className="framer-text framer-styles-preset-2ffhdr"
                                  
                                  style={{
                                    '--framer-text-color': 'var(--extracted-1eung3n, rgb(153, 153, 153))'
                                  }}
                                >
                                  Floor LLC
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="framer-1ro4j7r"
                          data-framer-name="Image"
                          style={{
                            opacity: '1'
                          }}
                        >
                          <div
                            data-framer-background-image-wrapper="true"
                            style={{
                              borderRadius: 'inherit',
                              inset: '0px',
                              position: 'absolute'
                            }}
                          >
                            <img
                              alt=""
                              decoding="async"
                              sizes="max((max(min(max(100vw - 64px, 1px), 1440px), 50px) - 182px) / 2, 1px)"
                              src="./Strix — Creative Agency _ Business Website_files/1xmlxfUdZxLeIpcEoFU4I1QLjyw.jpg"
                              srcSet="https://framerusercontent.com/images/1xmlxfUdZxLeIpcEoFU4I1QLjyw.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/1xmlxfUdZxLeIpcEoFU4I1QLjyw.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/1xmlxfUdZxLeIpcEoFU4I1QLjyw.jpg?scale-down-to=2048 2048w,https://framerusercontent.com/images/1xmlxfUdZxLeIpcEoFU4I1QLjyw.jpg 3600w"
                              style={{
                                borderRadius: 'inherit',
                                display: 'block',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center center',
                                width: '100%'
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <section
            className="framer-9qnvbk"
            data-framer-name="Blogs"
            name="Blogs"
          >
            <div
              className="framer-17hxhxk"
              data-framer-name="Container"
              name="Container"
            >
              <div
                className="framer-6hnxmw"
                data-framer-name="Heading"
                name="Heading"
              >
                <div
                  className="framer-1vzozgv"
                  data-framer-name="Title"
                  name="Title"
                >
                  <div
                    className="framer-lpgaxd-container"
                    style={{
                      opacity: '1',
                      transform: 'none'
                    }}
                  >
                    <div
                      style={{
                        display: 'contents'
                      }}
                    >
                      <svg
                        color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                        focusable="false"
                        style={{
                          color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                          display: 'inline-block',
                          fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                        
                          height: '100%',
                          userSelect: 'none',
                          width: '100%'
                        }}
                        viewBox="0 0 256 256"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                          weight="regular"
                        >
                          <path d="M229.66,157.66l-48,48a8,8,0,0,1-11.32-11.32L204.69,160H128A104.11,104.11,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.69l-34.35-34.34a8,8,0,0,1,11.32-11.32l48,48A8,8,0,0,1,229.66,157.66Z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    className="framer-651pti"
                    
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    
                      justifyContent: 'flex-start',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <h2
                      className="framer-text framer-styles-preset-18i0ski"
                      
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          opacity: '1',
                          transform: 'none',
                          willChange: 'auto'
                        }}
                      >
                        Latest news
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="framer-51w8u8-container">
                  <a
                    className="framer-KDYjx framer-bHbcC framer-h6bfb5 framer-v-13ixrju framer-5uk4vs"
                    
                    
                    href="https://strix.framer.website/blog"
                    style={{
                      '--border-bottom-width': '0px',
                      '--border-color': 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                      '--border-left-width': '0px',
                      '--border-right-width': '0px',
                      '--border-style': 'solid',
                      '--border-top-width': '0px',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '100px',
                      opacity: '1'
                    }}
                  >
                    <div
                      className="framer-suukqt"
                      
                      
                    >
                      <div
                        className="framer-1xmx5dx"
                        
                        style={{
                          color: 'rgb(0, 153, 255)',
                          textDecoration: 'underline',
                          display: 'flex',
                          flexDirection: 'column',
                        
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          
                        >
                          View all
                        </p>
                      </div>
                      <div
                        className="framer-1b5sv9n"
                        
                        style={{
                          color: 'rgb(0, 153, 255)',
                          textDecoration: 'underline',
                          display: 'flex',
                          flexDirection: 'column',
                        
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text framer-styles-preset-zxebov"
                          
                        >
                          View all
                        </p>
                      </div>
                    </div>
                    <div
                      className="framer-6k82ok-container"
                      
                    >
                      <div
                        style={{
                          display: 'contents'
                        }}
                      >
                        <svg
                          color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                          focusable="false"
                          style={{
                            color: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                            display: 'inline-block',
                            fill: 'var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))',
                          
                            height: '100%',
                            userSelect: 'none',
                            width: '100%'
                          }}
                          viewBox="0 0 256 256"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            color="var(--token-b1f034d9-4b93-455b-aa10-496f0b0bd376, rgb(255, 255, 255))"
                            weight="fill"
                          >
                            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,109.66-32,32a8,8,0,0,1-11.32-11.32L148.69,136H88a8,8,0,0,1,0-16h60.69l-18.35-18.34a8,8,0,0,1,11.32-11.32l32,32A8,8,0,0,1,173.66,133.66Z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                className="framer-k29y3k"
                data-framer-name="Blog collection"
                name="Blog collection"
              >
                <div
                  className="framer-vosnd6-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <a
                    className="framer-l3uR5 framer-opmY9 framer-sTOlC framer-bHbcC framer-p7bmve framer-v-p7bmve framer-1s0cva2"
                    
                    href="https://strix.framer.website/blog/why-user-experience-(ux)-design-is-crucial"
                    style={{
                      backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                      borderRadius: '12px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-uyd1vw"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '10px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1eh8nsk"
                        data-framer-name="Image"
                        style={{
                          borderRadius: '12px',
                          opacity: '1'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            inset: '0px',
                            position: 'absolute'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            loading="lazy"
                            sizes="calc(max((min(max(100vw - 64px, 1px), 1440px) - 48px) / 3, 50px) * 1.0011)"
                            src="./Strix — Creative Agency _ Business Website_files/EIfEEVKAVXlU48hG17mymtuHTQ.webp"
                            srcSet="https://framerusercontent.com/images/EIfEEVKAVXlU48hG17mymtuHTQ.webp?scale-down-to=512 512w,https://framerusercontent.com/images/EIfEEVKAVXlU48hG17mymtuHTQ.webp?scale-down-to=1024 1024w,https://framerusercontent.com/images/EIfEEVKAVXlU48hG17mymtuHTQ.webp 2000w"
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-1ld5gnc"
                      data-framer-name="Post"
                      
                    >
                      <div
                        className="framer-lpmhno"
                        data-framer-name="Hover item"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          opacity: '1'
                        }}
                      />
                      <div
                        className="framer-15xfspm"
                        
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h5
                          className="framer-text framer-styles-preset-voj4iq"
                          data-styles-preset="QHk2CuZUI"
                        >
                          Why Exceptional User Experience (UX) Design is Crucial
                        </h5>
                      </div>
                      <div
                        className="framer-xnnmof"
                        data-framer-name="Read time"
                        style={{
                          backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                          borderRadius: '100px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-5dkwb9"
                          
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p
                            className="framer-text framer-styles-preset-zxebov"
                            
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            6 min
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  className="framer-vosnd6-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <a
                    className="framer-l3uR5 framer-opmY9 framer-sTOlC framer-bHbcC framer-p7bmve framer-v-p7bmve framer-1s0cva2"
                    
                    href="https://strix.framer.website/blog/strategies-for-engaging-your-audience-in-2024"
                    style={{
                      backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                      borderRadius: '12px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-uyd1vw"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '10px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1eh8nsk"
                        data-framer-name="Image"
                        style={{
                          borderRadius: '12px',
                          opacity: '1'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            inset: '0px',
                            position: 'absolute'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            loading="lazy"
                            sizes="calc(max((min(max(100vw - 64px, 1px), 1440px) - 48px) / 3, 50px) * 1.0011)"
                            src="./Strix — Creative Agency _ Business Website_files/2szrBbL7kfvfdWfNn5iXTLOcYWI.jpg"
                            srcSet="https://framerusercontent.com/images/2szrBbL7kfvfdWfNn5iXTLOcYWI.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/2szrBbL7kfvfdWfNn5iXTLOcYWI.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/2szrBbL7kfvfdWfNn5iXTLOcYWI.jpg?scale-down-to=2048 2048w,https://framerusercontent.com/images/2szrBbL7kfvfdWfNn5iXTLOcYWI.jpg?scale-down-to=4096 4096w,https://framerusercontent.com/images/2szrBbL7kfvfdWfNn5iXTLOcYWI.jpg 4912w"
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-1ld5gnc"
                      data-framer-name="Post"
                      
                    >
                      <div
                        className="framer-lpmhno"
                        data-framer-name="Hover item"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          opacity: '1'
                        }}
                      />
                      <div
                        className="framer-15xfspm"
                        
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h5
                          className="framer-text framer-styles-preset-voj4iq"
                          data-styles-preset="QHk2CuZUI"
                        >
                          Top Strategies for Engaging Your Audience in 2024
                        </h5>
                      </div>
                      <div
                        className="framer-xnnmof"
                        data-framer-name="Read time"
                        style={{
                          backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                          borderRadius: '100px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-5dkwb9"
                          
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p
                            className="framer-text framer-styles-preset-zxebov"
                            
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            2 min
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  className="framer-vosnd6-container"
                  style={{
                    opacity: '1',
                    transform: 'none'
                  }}
                >
                  <a
                    className="framer-l3uR5 framer-opmY9 framer-sTOlC framer-bHbcC framer-p7bmve framer-v-p7bmve framer-1s0cva2"
                    
                    href="https://strix.framer.website/blog/the-role-of-seo-in-modern-web-design"
                    style={{
                      backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                      borderRadius: '12px',
                      opacity: '1',
                      width: '100%'
                    }}
                  >
                    <div
                      className="framer-uyd1vw"
                      data-framer-name="Image wrapper"
                      style={{
                        borderRadius: '10px',
                        opacity: '1'
                      }}
                    >
                      <div
                        className="framer-1eh8nsk"
                        data-framer-name="Image"
                        style={{
                          borderRadius: '12px',
                          transform: 'none',
                          transformOrigin: '50% 50% 0px'
                        }}
                      >
                        <div
                          data-framer-background-image-wrapper="true"
                          style={{
                            borderRadius: 'inherit',
                            inset: '0px',
                            position: 'absolute'
                          }}
                        >
                          <img
                            alt=""
                            decoding="async"
                            loading="lazy"
                            sizes="calc(max((min(max(100vw - 64px, 1px), 1440px) - 48px) / 3, 50px) * 1.0011)"
                            src="./Strix — Creative Agency _ Business Website_files/AexWIexw9UpERMoGGpFrGc5Do.jpg"
                            srcSet="https://framerusercontent.com/images/AexWIexw9UpERMoGGpFrGc5Do.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/AexWIexw9UpERMoGGpFrGc5Do.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/AexWIexw9UpERMoGGpFrGc5Do.jpg?scale-down-to=2048 2048w,https://framerusercontent.com/images/AexWIexw9UpERMoGGpFrGc5Do.jpg 3888w"
                            style={{
                              borderRadius: 'inherit',
                              display: 'block',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center center',
                              width: '100%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="framer-1ld5gnc"
                      data-framer-name="Post"
                      
                    >
                      <div
                        className="framer-lpmhno"
                        data-framer-name="Hover item"
                        style={{
                          backgroundColor: 'var(--token-a9fff0b2-01e6-4d97-91f3-c786ccf95baf, rgb(13, 13, 13))',
                          transform: 'none',
                          transformOrigin: '50% 50% 0px'
                        }}
                      />
                      <div
                        className="framer-15xfspm"
                        
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        
                          justifyContent: 'flex-start',
                          opacity: '1',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <h5
                          className="framer-text framer-styles-preset-voj4iq"
                          data-styles-preset="QHk2CuZUI"
                        >
                          The Essential Role of SEO in Modern Web Design
                        </h5>
                      </div>
                      <div
                        className="framer-xnnmof"
                        data-framer-name="Read time"
                        style={{
                          backgroundColor: 'var(--token-57a2a35c-2d73-4d62-bedb-7a0ba6025f10, rgb(26, 26, 26))',
                          borderRadius: '100px',
                          opacity: '1'
                        }}
                      >
                        <div
                          className="framer-5dkwb9"
                          
                          style={{
                            '--extracted-r6o4lv': 'var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104))',
                            display: 'flex',
                            flexDirection: 'column',
                          
                            justifyContent: 'flex-start',
                            opacity: '1',
                            outline: 'none',
                            transform: 'none'
                          }}
                        >
                          <p
                            className="framer-text framer-styles-preset-zxebov"
                            
                            style={{
                              '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-983baa47-43e1-4c41-8f9b-c979896e9d9c, rgb(104, 104, 104)))'
                            }}
                          >
                            3 min
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section> */}
          <div className="ssr-variant hidden-6a8w7z hidden-7sdega">
            <div
              className="framer-6q3wky-container"
              style={{
                opacity: '1',
                transform: 'none'
              }}
            >
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home